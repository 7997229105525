<div class="row pt-3">
  <label class="col-sm-2 col-form-label mt-0 mt-md-2">{{formField.label}}</label>
  <div class="col-sm-9" [formGroup]="formGroup">
    <input [formControlName]="formField.key" type="text" class="form-control rounded d-flex p-2"
      [placeholder]="formField.placeholder">

    <div class="text-danger pt-1" *ngIf="
    formControl[formField.key].touched &&
    formControl[formField.key].errors?.required
          ">
      <small>{{formField.label}} is required. </small>
    </div>
  </div>
</div>
