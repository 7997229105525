import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { PAGE_COUNT, ROLE_TYPE, SUCCESS_MESSAGES } from 'src/app/common/constants';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { User } from 'src/app/models/user';
import { deleteUserById, getUsers } from 'src/app/services/manager.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-consumers',
  templateUrl: './consumers.component.html',
  styleUrls: ['./consumers.component.scss']
})
export class ConsumersComponent implements OnInit {
  public consumerList: User[];
  public RoleType = ROLE_TYPE;
  public searchText: string = '';
  public page: number = 1;
  public pageCount: number = PAGE_COUNT;
  public totalPage: number = 0;
  searchInputObject : Subject<string> = new Subject();
  constructor(
    private spinner: NgxSpinnerService, private modalService: NgbModal, private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getConsumers(1);
    this.searchInputObject.pipe(debounceTime(500),distinctUntilChanged()).subscribe((searchText) =>{
        this.getConsumers(1);
   });
  }

  getConsumers = async (page) => {
    this.page = page;
    this.spinner.show();
    try {
      let { results, totalCount } = await getUsers(ROLE_TYPE.consumer, this.searchText.trim(), null, this.page, this.pageCount, null);
      this.totalPage = totalCount;
      this.consumerList = results;
    } catch (err) {
      this.toastr.error(err.message);
    } finally {
      this.spinner.hide();
    }
  }

  setPage = (page) => {
    this.getConsumers(page);
  }

  deleteConsumer = async (id) => {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.message = 'Are you sure you want to delete the Consumer?';
    modalRef.componentInstance.showCancel = true;
    modalRef.result.then((result: any) => {
      if (result) {
        this.handleDeleteConsumer(id);
      }
    });
  }

  handleDeleteConsumer = async (id) => {
    this.spinner.show();
    try {
      let { success } = await deleteUserById(id);
      if (success) {
        this.toastr.success(SUCCESS_MESSAGES.CONSUMER_DELETED);
        this.getConsumers(1);
      }
    } catch (err) {
      this.toastr.error(err.message);
    }
    this.spinner.hide();
  }

  searchConsumers(event){
    this.searchInputObject.next(event)
  }
}
