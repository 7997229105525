<!-- Start Page content holder -->
<div class="" id="content">
  <div class="Main-hesd">
    <h2 class="font-weight-bold font-style-normal">Dashboard</h2>
  </div>
  <div class="chart-cri">

    <div class="row">
      <div class="col-md-4" *ngFor="let chart of pieChartsData;let i=index">
        <div class="chart-box">
          <h2>{{pieChartsHeadings[i]}}</h2>
          <h6>{{pieChartsTitles[i]}}</h6>
          <div>
            <ng-container *ngIf="chart.datasets?.[0]?.data?.length>0">
              <canvas baseChart [data]="chart" [type]="pieChartType" class="chart"
                [options]="pieChartOptions" [plugins]="pieChartPlugins">
              </canvas>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngFor="let chart of lineChartsConfig; let i=index">
      <div class="col-md-12">
        <div class="chart-box">
          <h3>{{titles[i]}}</h3>
          <div class="chart_div">
            <canvas baseChart id="myChart_demo1" [datasets]="chart?.datasets" [labels]="chart?.labels"
              [options]="lineChartOptions" [type]="lineChartType">
            </canvas>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
