// core modules
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ERROR_MESSAGES, SUCCESS_MESSAGES, VALIDATION_REGEX, ALLOWED_ROLE } from "../../common/constants";
import { ConfirmedValidator } from "../../common/customValidators/passwordMatch";
// project modules
import { completeNewPassword, SignIn } from "../../services/auth/auth.service";
import { getUser } from 'src/app/services/manager.service';
import { setInStorage } from 'src/app/common/utils';
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public resetPasswordForm: FormGroup;
  public action: string = "login";
  protected user: any;
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });

    this.resetPasswordForm = this.formBuilder.group(
      {
        password: [
          "",
          [Validators.required, Validators.pattern(VALIDATION_REGEX.PASSWORD)],
        ],
        cnfrmPassword: ["", [Validators.required]],
      },
      {
        validator: ConfirmedValidator("password", "cnfrmPassword"),
      }
    );
  }

  get formControl() {
    return this[this.getActiveForm()].controls;
  }

  getActiveForm = () => {
    switch (this.action) {
      case "login":
        return "loginForm";
      case "resetPassword":
        return "resetPasswordForm";
    }
  };

  onSubmit = () => {
    if (this.action === "login") {
      this.doLogin();
    } else {
      this.resetPassword();
    }
  };

  doLogin = async () => {
    let { email, password } = this.loginForm.value;
    this.spinner.show();
    let { message, result } = await SignIn(email.toLowerCase(), password);
    this.spinner.hide();
    if (!result) {
      this.toastr.error(message);
      return;
    }
    const { challengeName } = result;
    if (challengeName === "NEW_PASSWORD_REQUIRED") {
      this.goToReset(result);

      return;
    }
    this.getSuperAdmin();
  };

  getSuperAdmin = async () => {
    this.spinner.show();
    try {
      let result = await getUser();
        if(result && result.role && ALLOWED_ROLE.includes(result.role.id)){
        setInStorage('user', result);
        this.toastr.success(SUCCESS_MESSAGES.LOGIN_SUCCESSFUL);
        this.router.navigate(["/manager/dashboard"]);
        return;
      }
      this.toastr.error('Not Authorized.')     
    } catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }
  }

  resetPassword = async () => {
    const { result, message } = await completeNewPassword(
      this.user,
      this.resetPasswordForm.value.password
    );
    if (!result) {
      this.toastr.error(message);
    }
    this.toastr.success(SUCCESS_MESSAGES.RESET_PASSWORD);
    this.router.navigate(["/manager/dashboard"]);
  };

  goToReset = (result) => {
    this.action = "resetPassword";
    this.user = result;
    this.toastr.error(ERROR_MESSAGES.CHANGE_PASSWORD);
  };
}
