import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ERROR_MESSAGES, MAX_FILE_SIZE, ROLE_TYPE, SUCCESS_MESSAGES, VALIDATION_REGEX } from 'src/app/common/constants';
import { getS3Url, removeNull, removeNullOrEmpty } from 'src/app/common/utils';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { Country } from 'src/app/models/country';
import { Language } from 'src/app/models/language';
import { User } from 'src/app/models/user';
import { getUserById, postUser, putUser, sendCredentialById } from 'src/app/services/manager.service';
import { getMetaData, updateFile, uploadFile } from 'src/app/services/product.service';

@Component({
  selector: 'app-add-consumer',
  templateUrl: './add-consumer.component.html',
  styleUrls: ['./add-consumer.component.scss']
})
export class AddConsumerComponent implements OnInit {
  public detailsForm: FormGroup;
  public countries: Country[];
  public languages: Language[];
  public user: User;
  public photoURL;
  public photo: File;
  constructor(
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.detailsForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      url: ['', [Validators.required, Validators.pattern(VALIDATION_REGEX.WEBSITE)]],
      country_id: [null, Validators.required],
      language_id: [null],
      contact_number: ['', Validators.pattern(VALIDATION_REGEX.CONTACT_NUMBER)],
      description: [''],
      enable: [true],
    });
    this.getCountries();
    this.getLanguages();
    if (this.activatedRoute.snapshot.paramMap.get('id')) {
      this.getUser(this.activatedRoute.snapshot.paramMap.get('id'));
    }

  }

  get formControl() {
    return this.detailsForm.controls;
  }

  createUser = async () => {
    this.spinner.show();
    try {
      if (this.user) {
        let data = {
          id: this.user.id,
        }
        const allowedFields = ['first_name', 'url', 'description', 'language_id', 'contact_number', 'enable', 'photo_url', 'country_id'];
        for (let key of allowedFields) {
          data[key] = this.detailsForm.value[key];
        }
        data = removeNull(data);
        if (this.photo && this.photoURL !== this.user.photo_url) data['photo_url'] = await this.getFileUrl();
        if (this.user.photo_url && !this.photoURL) data['photo_url'] = '';
        await putUser(data, null, null);
      } else {
        this.detailsForm.value.email = this.detailsForm.value.email ? this.detailsForm.value.email.toLowerCase() : '';
        let data = { ... this.detailsForm.value };
        data.role_id = ROLE_TYPE.consumer;
        if (this.photo) {
          data.photo_url = await this.getFileUrl();
        }
        await postUser(removeNullOrEmpty(data));
      }
      let message = this.user ? SUCCESS_MESSAGES.CONSUMER_UPDATED : SUCCESS_MESSAGES.CONSUMER_CREATED;
      this.toastr.success(message);
      this.router.navigate(["/manager/consumers"]);
    } catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }

  }

  getFileUrl = async () => {
    let params = {
      file_name: this.photo.name,
      content_type: this.photo.type
    }
    this.spinner.show();
    try {
      // get signed url from backend
      let { key, url } = await updateFile(params) || {};
      // upload file to signed url
      await uploadFile(url, this.photo);
      this.spinner.hide();
      // return s3 url
      return getS3Url(key);
    } catch (err) {
      this.spinner.hide();
      let message = err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    }
    return null;
  }

  getCountries = async () => {
    this.spinner.show();
    try {
      this.countries = await getMetaData('country');
    } catch (err) {
      let message = err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    } finally {
      this.spinner.hide();
    }
  }

  getLanguages = async () => {
    this.spinner.show();
    try {
      this.languages = await getMetaData('language');
      this.spinner.hide();
    } catch (err) {
      let message = err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    } finally {
      this.spinner.hide();
    }
  }

  getUser = async (id) => {
    this.spinner.show();
    try {
      let { success, result } = await getUserById(id, null);
      if (success) {
        this.user = result;
        const nonEditable = ['email'];
        for (let field of nonEditable) {
          this.detailsForm.controls[field].disable();
        }

        const fieldsToPatch = ['first_name', 'contact_number', 'email', 'url', 'description', 'language_id', 'country_id', 'enable'];
        let patch = {};
        for (let field of fieldsToPatch) {
          if (this.user.hasOwnProperty(field)) patch[field] = this.user[field];
        }
        this.detailsForm.patchValue(patch);
        if (result.photo_url) this.photoURL = result.photo_url;
      }
    } catch (err) {
      this.toastr.error(err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }
  }

  selectFile = async (event, type) => {
    let { target: { files } } = event;
    let [file] = files;
    let [fileType] = file.type.split('/');

    if ((type === 'image') && fileType !== type) {
      const modalRef = this.modalService.open(ConfirmationModalComponent);
      modalRef.componentInstance.message = 'Invalid File Format.';
      event.srcElement.value = null;
      return;
    }

    if (file && file.size > MAX_FILE_SIZE) {
      const modalRef = this.modalService.open(ConfirmationModalComponent);
      modalRef.componentInstance.message = 'File too Big! Please select a file less than 100mb';
      event.srcElement.value = null;
      return;
    }

    if (file) {
      this.photo = file;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.photoURL = reader.result;
      }
    }
  }

  sendCredential = async () => {
    this.spinner.show();
    try {
      let { success } = await sendCredentialById(this.user.id);
      if (success) {
        let message = SUCCESS_MESSAGES.PASSWORD_CHANGED;
        this.toastr.success(message);
      }
    } catch (err) {
      this.toastr.error(err && err.message ? err.message.message ? err.message.message : err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }
  }
}
