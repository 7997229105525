// core modules
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

// project modules
import { SignOut } from '../../services/auth/auth.service';
import { getUser } from '../../services/manager.service';
import { getFromStorage, setInStorage, clearStorage } from 'src/app/common/utils';
import { Manager } from '../../models/manager';
import { ERROR_MESSAGES } from 'src/app/common/constants';
@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() toggleSideNav: EventEmitter<boolean> = new EventEmitter();
  public showDropDown: boolean = false;
  public user: Manager;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.loadUser();
  }

  clickMenu = () => {
    this.toggleSideNav.emit(true);
  }

  logOut = async () => {
    this.spinner.show()
    try{
    await SignOut();
    clearStorage();
    }catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
      this.router.navigate(["/login"]);
    }
  }

  loadUser = async () => {
    if(getFromStorage('user')) {
      this.user = getFromStorage('user');
      return;
    }
    try{
    this.user = await getUser();
    setInStorage('user', this.user);
    }catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }
  }
}
