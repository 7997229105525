<div class="main-produ ml-2 mt-5 mb-5 mr-2 d-inline-block w-100">
  <h2 class="font-weight-bold">Manufacturers <span class="search-bx">

      <input type="text" name="search" id="searchText" [(ngModel)]="searchText" placeholder="Quick search"
        (keyup.enter)="getManufacturer(1)"
        class="pl-5 pr-3 pb-3 pt-3 font-size-18 font-weight-normal rounded border border-light ">
      <span class="add-user"><a [routerLink]="['/manager/manufacturer']"
          class="rounded font-weight-bold ml-4 pt-3 pb-3 pl-5 pr-5  font-size-18 font-style-normal">Add a
          Manufacturer</a></span>
    </span></h2>
</div>
<div>
  <br>
  <div *ngIf=" ManuList && ManuList?.length> 0">
    <div class=" table-responsive outer-table-manu">
      <div class="force-overflow"></div>
      <table class="table table-bordered table-fixed table-zeus">
        <thead>
          <tr>
            <th scope="col" sortable="first_name" (sort)="onSort($event)">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Manufacturer Id</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let manu of ManuList; let i = index">

            <td class="cursor-pointer" [routerLink]="['/manager/manufacturer', manu?.id]">{{manu?.first_name}}
              {{ manu?.last_name? manu.last_name: ''}}</td>
            <td class="cursor-pointer" [routerLink]="['/manager/manufacturer', manu?.id]">{{manu?.email}}</td>
            <td class="cursor-pointer">{{manu?.id}}</td>
            <td class="cursor-pointer">
              <a class="remove pl-3 cursor-pointer" (click)="deleteManu(manu?.id)">
                <i class="fas fa-trash-alt"></i></a>
                 <button type="button" (click)="sendCredential(manu?.id)" class="btn btn-primary ml-3">Send Credentials</button>
            </td>

          </tr>

        </tbody>
      </table>
    </div>

    <app-pagination [totalItems]="totalPage" [pageSize]="pageCount" [currentPage]="page" (pageClicked)="setPage($event)"
      *ngIf="totalPage > pageCount">
    </app-pagination>
  </div>
  <div *ngIf="!ManuList || ManuList?.length === 0"> No Manufacturers</div>
</div>
