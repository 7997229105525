// core modules
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
// module modules
import { environment } from "../../environments/environment";

export const removeNullOrEmpty = (json) => {
  let keys = Object.keys(json);
  if (keys && keys.length > 0) {
    for (let key of keys) {
      if (json[key] === null || json[key] === "") {
        delete json[key];
      }
    }
  }
  return json;
};

export const removeNull = (json) => {
  let keys = Object.keys(json);
  if (keys && keys.length > 0) {
    for (let key of keys) {
      if (json[key] === null) {
        delete json[key];
      }
    }
  }
  return json;
};

export const setInStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromStorage = (key) => {
  return JSON.parse(localStorage.getItem(key)) || null;
};

export const clearStorage = () => {
  localStorage.clear();
};

export const getS3Url = (key) => {
  return `https://${environment.amplify.Storage.AWSS3.bucket}.s3.${environment.amplify.Storage.AWSS3.region}.amazonaws.com/${key}`;
};

export const convertMSToNgbDate = (ms) => {
  let d: NgbDateStruct = {
    day: new Date(ms).getDate(),
    month: new Date(ms).getMonth() + 1,
    year: new Date(ms).getFullYear(),
  };
  return d;
};
