<div class="main-produ mt-5 mb-5 d-inline-block w-100">

  <h2 class="font-weight-bold font-style-normal">Products <span class="search-bx float-right">

      <input type="text" name="search"
        class="pl-5 pr-3 pb-3 pt-3 font-size-18 font-weight-normal rounded border border-light " id="searchText"
        [(ngModel)]="searchText" placeholder="Quick search" (ngModelChange)="searchProject($event)">
      <span><a [routerLink]="['/manager/product']"
          class="rounded font-weight-bold  pt-3 pb-3 pl-5 pr-5 ml-0 ml-md-3 font-size-18 font-style-normal">Add a
          product</a></span>
    </span></h2>

</div>
<br>
<div class="wrapper">
  <div *ngIf="products && products?.length > 0">
    <div class="table-responsive outer-tbl">
      <div class="force-overflow"></div>
      <table class="table table-bordered table-fixed">
        <thead>
          <tr>
            <th scope="col" sortable="ean" (sort)="onSort($event)">EAN</th>
            <th scope="col" sortable="title" (sort)="onSort($event)">Title</th>
            <th scope="col">Description</th>
            <th class="col4" scope="col">Country</th>
            <th scope="col">Enabled</th>
            <th scope="col">Action</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let product of products; let i = index">
            <td class="cursor-pointer" [routerLink]="['/manager/product', product?.id]"> {{product?.ean}} </td>
            <td class="cursor-pointer" [routerLink]="['/manager/product', product?.id]">{{product?.title}}</td>
            <td class="cursor-pointer" [routerLink]="['/manager/product', product?.id]">{{product?.description}}</td>
            <td class="cursor-pointer" [routerLink]="['/manager/product', product?.id]">{{product?.country?.code}}</td>
            <td>
              <div class="custom-control custom-switch ">
                <input type="checkbox" class="custom-control-input" [id]="i" [checked]="product?.enable"
                  (change)="changeEnable(i, product?.enable)" />
                <label class="custom-control-label" [for]="i"></label>
              </div>
            </td>
            <td class="cursor-pointer">
              <a class="remove pl-3" (click)="deleteProduct(product?.id)"><i class="fas fa-trash-alt"></i></a>
            </td>
          </tr>


      </table>
    </div>

    <app-pagination [totalItems]="totalPage" [pageSize]="pageCount" (pageClicked)="setPage($event)" [currentPage]="page"
      *ngIf="totalPage > pageCount">
    </app-pagination>
  </div>
  <div *ngIf="!products || products?.length === 0"> NO PRODUCTS</div>
</div>
