import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { User } from "src/app/models/user";
import { removeNullOrEmpty } from "../../common/utils";
import { Country } from "../../models/country";
import { Role } from "../../models/role";
import {
  getManufaturer,
  getUserById,
  postUser,
  putUser,
} from "../../services/manager.service";
import { getMetaData } from "../../services/product.service";
import {
  ERROR_MESSAGES,
  ROLE_TYPE,
  SUCCESS_MESSAGES,
  VALIDATION_REGEX,
} from "./../../common/constants";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
  public detailsForm: FormGroup;
  public countries: Country[];
  public roles: Role[];
  public user: User;
  public RoleType = ROLE_TYPE;
  public resetPassword: boolean = false;
  public newPassword: any;
  public passwordPattern = VALIDATION_REGEX.COGNITO_PASSWORD;
  manufaturers: { id: string | number; name: string }[] = [];
  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.detailsForm = this.formBuilder.group({
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      country_id: [null, Validators.required],
      role_id: [null, Validators.required],
      url: [""],
      manufacturer_id: ["", [Validators.required]],
    });
    this.getRoles();
    this.getCountries();
    this.getManufaturer();
    if (this.activatedRoute.snapshot.paramMap.get("id")) {
      this.editUser(this.activatedRoute.snapshot.paramMap.get("id"));
    }
    this.detailsForm
      .get("role_id")
      .valueChanges.subscribe((x) => this.handleRoleChange(x));
  }

  handleRoleChange = (role) => {
    if (role == ROLE_TYPE.consumer) {
      this.detailsForm
        .get("url")
        .setValidators([
          Validators.required,
          Validators.pattern(VALIDATION_REGEX.WEBSITE),
        ]);
    } else {
      this.detailsForm.get("url").clearValidators();
    }
    this.detailsForm.get("url").updateValueAndValidity();
  };

  get formControl() {
    return this.detailsForm.controls;
  }

  createUser = async () => {
    this.spinner.show();
    try {
      if (this.user) {
        let data = {
          id: this.user.id,
        };
        if (this.resetPassword) {
          data["password"] = this.newPassword;
        } else {
          const allowedFields = ["first_name", "last_name","manufacturer_id"];
          if (this.detailsForm.get("role_id").value == ROLE_TYPE.consumer)
            allowedFields.push("url");
          for (let key of allowedFields) {
            if (this.detailsForm.value[key])
              data[key] = this.detailsForm.value[key];
          }
        }
        await putUser(data, null, null);
      } else {
        this.detailsForm.value.email = this.detailsForm.value.email
          ? this.detailsForm.value.email.toLowerCase()
          : "";
        await postUser(removeNullOrEmpty(this.detailsForm.value));
      }
      let message = this.user
        ? SUCCESS_MESSAGES.USER_UPDATED
        : SUCCESS_MESSAGES.USER_CREATED;
      this.toastr.success(message);
      if (this.resetPassword) {
        this.newPassword = null;
        this.resetPassword = false;
        this.router.navigate(["/manager/user", this.user.id]);
      } else this.router.navigate(["/manager/users"]);
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  getRoles = async () => {
    this.spinner.show();
    try {
      this.roles = await getMetaData("role");
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  getCountries = async () => {
    this.spinner.show();
    try {
      this.countries = await getMetaData("country");
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  editUser = async (id) => {
    this.spinner.show();
    try {
      let { success, result } = await getUserById(id, null);
      if (success) {
        this.user = result;
      }
    } catch (err) {
      this.toastr.error(
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
    const nonEditable = ["country_id", "role_id"];
    for (let field of nonEditable) {
      this.detailsForm.controls[field].disable();
    }
    const fieldsToPatch = [
      "first_name",
      "last_name",
      "email",
      "country_id",
      "role_id",
      "url",
      "manufacturer_id",
    ];
    for (let field of fieldsToPatch) {
      let patch = {};
      patch[field] = field !== "role_id" ? this.user[field] : this.user.role.id;
      this.detailsForm.patchValue(patch);
    }
    this.detailsForm.patchValue({
      manufacturer_id: this.user.users_manufacturers[0].manufacturer_id,
    });
  };
  getManufaturer = async () => {
    this.spinner.show();
    try {
      let { success, result, message } = await getManufaturer();
      if (success) {
        this.manufaturers = result;
        return;
      }
      this.toastr.error(message ? message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } catch (err) {
      this.toastr.error(
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };
}
