import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-page-title",
  templateUrl: "./page-title.component.html",
  styleUrls: ["./page-title.component.scss"],
})
export class PageTitleComponent implements OnInit {
  @Input() public type: string = "";
  @Input() public hideSearch: boolean = false;
  @Input() public hideAddNew: boolean = false;

  public searchText: string = "";
  @Output() onSearch: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onSearchKeyUp(): void {
    this.onSearch.emit(this.searchText);
  }
}
