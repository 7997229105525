// project modules
import { URLS } from "../common/constants";
import { call } from "./backend.service";
// core modules
const util = require("util");

export const getUser = async () => {
  let { success, result } = await call(URLS.USER, "get");
  if (success) {
    return result;
  }
};

export const getApi = async () => {
  let { success, results } = await call(URLS.GET_APIS, "get");
  if (success) {
    return results;
  }
};

export const AddApi = async (params) => {
  return await call(URLS.POST_APIS, "put", params);
};

export const deleteApiById = async (id) => {
  const url = util.format(URLS.DELETE_API_BY_ID, id);
  return await call(url, "del");
};

export const postUser = async (data) => {
  return await call(URLS.USER, "post", data);
};

export const getUsers = async (
  role = null,
  name = null,
  userType = null,
  page = null,
  count = null,
  paginationToken = null
) => {
  let queryParams = {};
  if (name) queryParams["name"] = name;
  if (role) {
    if (typeof role === "object") {
      role = role.join(",");
    }
    queryParams["role_id"] = role;
  }
  if (page) queryParams["page"] = page;
  if (count) queryParams["limit"] = count;
  if (userType) queryParams["type"] = userType;
  if (paginationToken) queryParams["paginationToken"] = paginationToken;

  let result = await call(URLS.USERS, "get", null, queryParams);
  if (result.success) {
    return result;
  }
};

export const putUser = async (data = null, id = null, status = null) => {
  console.log(data);
  let queryParams = {};
  if (id) queryParams["cognito_id"] = id;
  if (status) queryParams["status"] = status;
  return await call(URLS.USER, "put", data, queryParams);
};

export const deleteUserById = async (id) => {
  const url = util.format(URLS.DELETE_USER_BY_ID, id);
  return await call(url, "del");
};

export const getUserById = async (id, type = null) => {
  let queryParams = {};
  if (type) queryParams["type"] = type;

  const url = util.format(URLS.GET_USER_BY_ID, id);
  return await call(url, "get", null, queryParams);
};

export const getReport = async () => {
  let queryParams = {};
  let result = await call(URLS.GET_REPORTS, "get", null, queryParams);
  if (result.success) {
    return result;
  }
};

export const sendCredentialById = async (id) => {
  const url = util.format(URLS.SEND_CREDENTIAL, id);
  return await call(url, "post");
};

export const addContract = async (data) => {
  return await call(URLS.MANUFACTURER_CONTRACTS, "post", data);
};

export const getContractsById = async (id) => {
  const url = util.format(`${URLS.MANUFACTURER_CONTRACTS}/%s`, id);
  return await call(url, "get", null);
};

export const deleteContractById = async (id) => {
  const url = util.format(URLS.MANUFACTURER_CONTRACTS);
  return await call(url, "del", { id });
};

export const updateBilling = async (payload) => {
  const url = util.format(`${URLS.MANUFACTURER_BILLING}`);
  return await call(url, "post", payload);
};

export const getBillingById = async (id) => {
  const url = util.format(`${URLS.MANUFACTURER_BILLING}/%s`, id);
  return await call(url, "get", null);
};

export const getManufaturer = async () => {
  const url = util.format(`${URLS.MANUFACTURER}/all`);
  return await call(url, "get", null);
};