import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TextboxField } from "../models/field-textbox";

@Component({
  selector: "dy-form-textbox",
  templateUrl: "./dy-form-textbox.component.html",
  styleUrls: ["./dy-form-textbox.component.scss"],
})
export class DyFormTextboxComponent implements OnInit {
  @Input() formField: TextboxField;
  @Input() formGroup: FormGroup;
  constructor() {}

  ngOnInit(): void {
    console.log(this.formField);
  }
  get formControl() {
    return this.formGroup.controls;
  }
}
