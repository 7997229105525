<div class="row">
  <div class="col-sm-5">
    <h2 class="users-design">Users</h2>
  </div>
</div>
<br />
<div>
  <div class="users-part">
    <h2>User Details</h2>
    <form
      [formGroup]="detailsForm"
      class="d-inline-block rounded"
      *ngIf="!resetPassword"
    >
      <div class="form-group">
        <div class="row pt-3">
          <label class="col-sm-2 col-form-label mt-0 mt-md-2">First name</label>
          <div class="col-sm-9">
            <input
              formControlName="first_name"
              id="first_name"
              type="text"
              class="form-control rounded d-flex p-2"
              placeholder="Enter First name"
            />

            <div
              class="text-danger pt-1"
              *ngIf="
                formControl.first_name.touched &&
                formControl.first_name.errors?.required
              "
            >
              <small>First Name is required. </small>
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <label class="col-sm-2 col-form-label mt-0 mt-md-2">Last name</label>
          <div class="col-sm-9">
            <input
              formControlName="last_name"
              id="first_name"
              type="text"
              class="form-control rounded d-flex p-2"
              placeholder="Enter Last name"
            />

            <div
              class="text-danger pt-1"
              *ngIf="
                formControl.last_name.touched &&
                formControl.last_name.errors?.required
              "
            >
              <small>Last Name is required. </small>
            </div>
          </div>
        </div>

        <div class="row pt-3">
          <label class="col-sm-2 col-form-label mt-0 mt-md-2">Email</label>
          <div class="col-sm-9">
            <input
              formControlName="email"
              id="email"
              type="text"
              class="form-control rounded d-flex p-2 wt-50"
              placeholder="Enter Email"
              [readonly]="user"
              [ngClass]="{ 'disble-box': user }"
            />

            <div
              class="text-danger pt-1"
              *ngIf="
                formControl.email.touched && formControl.email.errors?.required
              "
            >
              <small>Email is required. </small>
            </div>
            <div
              class="text-danger pt-1"
              *ngIf="
                formControl.email.touched && formControl.email.errors?.email
              "
            >
              <small>Invalid Email. </small>
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <label class="col-sm-2 col-form-label mt-0 mt-md-2"
            >Select Country</label
          >
          <div class="col-sm-9">
            <select
              id="country_id"
              class="form-control rounded d-flex p-2 wt-50"
              formControlName="country_id"
              [ngClass]="{ 'disble-box': user }"
            >
              <option *ngFor="let country of countries" [value]="country.id">
                {{ country.name }}
              </option>
            </select>
            <div
              class="text-danger pt-1"
              *ngIf="
                formControl.country_id.touched &&
                formControl.country_id.errors?.required
              "
            >
              <small>Country is required. </small>
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <label class="col-sm-2 col-form-label mt-0 mt-md-2"
            >Select Role</label
          >
          <div class="col-sm-9">
            <select
              id="role_id"
              class="form-control rounded d-flex p-2 wt-50"
              formControlName="role_id"
              [ngClass]="{ 'disble-box': user }"
            >
              <option *ngFor="let role of roles" [value]="role.id">
                {{ role.name }}
              </option>
            </select>

            <div
              class="text-danger pt-1"
              *ngIf="
                formControl.role_id.touched &&
                formControl.role_id.errors?.required
              "
            >
              <small>Role is required. </small>
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <label class="col-sm-2 col-form-label mt-0 mt-md-2"
            >Select Manufaturer</label
          >
          <div class="col-sm-9">
            <select
              id="role_id"
              class="form-control rounded d-flex p-2 wt-50"
              formControlName="manufacturer_id"
            >
              <option selected disabled>Select</option>
              <option *ngFor="let option of manufaturers" [value]="option.id">
                {{ option.name }}
              </option>
            </select>
            <div
              class="text-danger pt-1"
              *ngIf="
                formControl.manufacturer_id.touched &&
                formControl.manufacturer_id.errors?.required
              "
            >
              <small>Manufaturer is required. </small>
            </div>
          </div>
        </div>
        <div
          class="row pt-3"
          *ngIf="detailsForm.get('role_id').value == RoleType.consumer"
        >
          <label class="col-sm-2 col-form-label mt-0 mt-md-2">URL</label>
          <div class="col-sm-9">
            <input
              formControlName="url"
              id="url"
              type="text"
              class="form-control rounded d-flex p-2 wt-50"
              placeholder="Enter URL"
            />

            <div
              class="text-danger pt-1"
              *ngIf="
                formControl.url.touched && formControl.url.errors?.required
              "
            >
              <small>URL is required. </small>
            </div>
            <div
              class="text-danger pt-1"
              *ngIf="formControl.url.touched && formControl.url.errors?.pattern"
            >
              <small>Invalid URL. </small>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="form-group">
      <div class="mt-3 d-flex justify-content-end" *ngIf="!resetPassword">
        <button
          type="button"
          *ngIf="user"
          class="btn btn-primary rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
          (click)="resetPassword = true"
        >
          Reset Password
        </button>
        <button
          type="button"
          class="font-weight-bold rounded border border-primary btn text-dark pl-5 pb-3 pr-5 pt-3"
          [routerLink]="['/manager/users']"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
          [disabled]="!detailsForm.valid"
          (click)="createUser()"
        >
          Save
        </button>
      </div>
    </div>

    <div *ngIf="resetPassword">
      <form class="d-inline-block rounded" #f="ngForm" name="form">
        <div class="form-group">
          <div class="row pt-3">
            <label class="col-sm-2 col-form-label mt-0 mt-md-2"
              >Reset Password</label
            >
            <div class="col-sm-9">
              <input
                id="reset_password"
                type="text"
                name="newPassword"
                required
                #newPass="ngModel"
                [pattern]="passwordPattern"
                class="form-control rounded d-flex p-2"
                placeholder="Enter New Password"
                [(ngModel)]="newPassword"
              />
              <div
                class="text-danger pt-1"
                *ngIf="newPass?.touched && newPass?.errors"
              >
                <div *ngIf="newPass?.errors?.required">
                  <small>Password is required. </small>
                </div>
                <div *ngIf="newPass?.errors?.pattern">
                  <small>Password is invalid. </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="form-group">
        <div class="mt-3 d-flex justify-content-end">
          <button
            type="button"
            class="font-weight-bold rounded border border-primary btn text-dark pl-5 pb-3 pr-5 pt-3"
            (click)="resetPassword = false"
            [routerLink]="['/manager/user', user.id]"
          >
            Cancel
          </button>
          <button
            type="button"
            [disabled]="!f.form.valid"
            class="btn btn-primary rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
            (click)="createUser()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
