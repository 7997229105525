// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  amplify: {
    Auth: {
      identityPoolId: "eu-central-1:bb9faf52-91c6-461c-bfc6-b8f8dce6be35",
      region: "eu-central-1",
      userPoolId: "eu-central-1_54FVMFfWD",
      userPoolWebClientId: "rd8sa5vmveld13afm5ij26nkp",
      oauth: {
        domain: ".auth.eu-central-1.amazoncognito.com",
        scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: "http://localhost:4200",
        redirectSignOut: "http://localhost:4200",
        responseType: "profile",
      },
    },
    API: {
      endpoints: [
        {
          name: "app-api",
          endpoint: "https://api-dev.describy.com/v0.1",
          region: "eu-central-1",
        },
      ],
    },
    Storage: {
      AWSS3: {
        bucket: "dev-describit",
        region: "eu-central-1",
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
