<app-page-title type="contract" hideSearch="true"></app-page-title>
<div>
  <div *ngIf="contracts?.length> 0">
    <div class="table-responsive outer-table-manu">
      <div class="force-overflow"></div>
      <table class="table table-bordered table-fixed table-zeus">
        <thead>
          <tr>
            <th scope="col" sortable="title" (sort)="onSort($event)">Contracts</th>
            <th scope="col">Signature date</th>
            <th scope="col">Signed</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let contract of contracts; let i = index">

            <td class="cursor-pointer" [routerLink]="['/manager/contract', contract?.id]">{{contract?.contract_title}}
            </td>
            <td>{{contract?.signature_date | date}}</td>
            <td>
              <i class="fas fa-check" *ngIf="contract?.signature_date"></i>
              <i class="fas fa-times" *ngIf="!contract?.signature_date"></i>
            </td>
            <td class="cursor-pointer">
              <a class="remove pl-3 cursor-pointer" [routerLink]="['/manager/contract', contract?.id]">
                <i class="fas fa-pencil-alt"></i>
              </a>
              <a class="remove pl-3 cursor-pointer" (click)="deleteContract(contract?.id)">
                <i class="fas fa-trash-alt"></i>
              </a>
            </td>

          </tr>

        </tbody>
      </table>
    </div>

    <app-pagination [totalItems]="totalPage" [pageSize]="pageCount" [currentPage]="page" (pageClicked)="setPage($event)"
      *ngIf="totalPage > pageCount">
    </app-pagination>
  </div>
  <div *ngIf="!contracts || contracts?.length === 0">No Contracts</div>
</div>
