import { Auth, Storage } from "aws-amplify";
import * as moment from "moment";
import { URLS } from "../common/constants";
import { call } from "./backend.service";
const util = require("util");
export const getAll = async (page, count) => {
  let queryParams = {
    page: page,
    limit: count,
  };
  let result = await call(URLS.GET_CONTRACTS, "get", null, queryParams);
  if (result.success) {
    return result;
  }
};

export const deleteById = async (id) => {
  const url = util.format(URLS.CONTRACT_BY_ID, id);
  return await call(url, "del");
};

export const getById = async (id) => {
  const url = util.format(URLS.CONTRACT_BY_ID, id);
  let { success, result } = await call(url, "get");

  if (success && result && result.contract_description) {
    // Read file from s3
    let response: any = await Storage.get(
      result.contract_description.substring(
        result.contract_description.indexOf("contracts/")
      ),
      {
        level: "protected",
        download: true,
      }
    );
    if (response) {
      result.contract_description_url = result.contract_description;
      result.contract_description = await response?.Body.text();
    }
  }
  return { success, result };
};
export const createContract = async (body) => {
  return upsertContract(body, "post");
};

export const updateContract = async (body) => {
  return upsertContract(body, "put");
};

const upsertContract = async (body, method) => {
  let key = `contracts/${body.user.name}/${moment.utc().valueOf()}.html`;
  //Upload file to s3

  await Storage.put(key, body.contract_description, {
    level: "protected",
    contentType: "text/html",
  });

  let { id } = await Auth.currentUserInfo();

  let params = {
    ...body,
    user: body.user.name,
    contract_description: `protected/${id}/${key}`,
  };
  if (method === "put") {
    delete params.user;
  }
  return call(URLS.CREATE_CONTRACT, method, params);
};
