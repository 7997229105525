import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable, of, OperatorFunction } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from "rxjs/operators";
import { TypeAheadField } from "../models/field-typeahead";

@Component({
  selector: "dy-form-typeahead",
  templateUrl: "./dy-form-typeahead.component.html",
  styleUrls: ["./dy-form-typeahead.component.scss"],
})
export class DyFormTypeaheadComponent implements OnInit {
  @Input() formField: TypeAheadField;
  @Input() formGroup: FormGroup;
  @Input() search: any;

  searching = false;
  searchFailed = false;

  constructor() {}

  ngOnInit(): void {}

  get formControl() {
    return this.formGroup.controls;
  }

  performSearch: OperatorFunction<string, any> = (text$: Observable<any>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this.search(term).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );
  formatter = (x: { value: string }) => x.value;
}
