<section class="login-page bg-secondary">
  <div class="container-fluid">

  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6">
        <div class="log-img">
          <img src="../../../assets/images/logo-left.svg">
        </div> 
      </div>
      <div class="col-lg-6">
        <!-- <div class="logo-part">
          <img src="../../../assets/images/flat_logo_orange.svg">
        </div>  -->
        <div class="login-part">
          <div class="form-wrapper">
            <h2 class="mb-0">Welcome to the </h2>
            <h2>management tool</h2>
            <form class="form-horizontal" [formGroup]="loginForm" *ngIf="action === 'login'">
              <div class="form-group">
                <label class="text-secondary">Login</label>
                <input type="text" class="form-control border-secondary" id="email" formControlName="email"
                  placeholder="john@gmail.com">
                <span class="text-danger pt-1" *ngIf="formControl.email.touched && formControl.email.errors?.required">
                  Email is required.
                </span>
                <span class="text-danger pt-1" *ngIf="formControl.email.touched && formControl.email.errors?.email">
                  Invalid Email.
                </span>
              </div>

              <div class="form-group">
                <label class="text-secondary">Password</label>
                <input type="Password" class="form-control border-secondary" id="password" formControlName="password"
                  placeholder="Password">
                <span class="text-danger pt-1"
                  *ngIf="formControl.password.touched && formControl.password.errors?.required">
                  Password is required.
                </span>
              </div>
            </form>
            <form class="form-horizontal" [formGroup]="resetPasswordForm" *ngIf="action === 'resetPassword'">
              <div class="form-group">
                <label class="text-secondary">Password</label>
                <input type="Password" class="form-control border-secondary" id="password" formControlName="password"
                  placeholder="Password">
                <span class="text-danger pt-1"
                  *ngIf="formControl.password.touched && formControl.password.errors?.required">
                  Password is required.
                </span>
                <span class="text-danger pt-1"
                  *ngIf="formControl.password.touched && formControl.password.errors?.pattern">
                  Password should be atleast 8 characters, should contain one uppercase letter, one lowercase letter, one
                  special character and one number.
                </span>
              </div>

              <div class="form-group">
                <label class="text-secondary">Confirm Password</label>     
                      <input type="Password" class="form-control border-secondary" id="cnfrmPassword"
                  formControlName="cnfrmPassword" placeholder="Confirm Password">
                <span class="text-danger pt-1"
                  *ngIf="formControl.cnfrmPassword.touched && formControl.cnfrmPassword.errors?.required">
                  Confirm Password is required.
                </span>
                <span class="text-danger pt-1"
                  *ngIf="formControl.cnfrmPassword.touched && formControl.cnfrmPassword.errors?.confirmedValidator">
                  Passwords do not match.
                </span>
              </div>
            </form>
            <button type="button" class="button_form btn btn-primary"
              [disabled]="action === 'login' ? !loginForm.valid : !resetPasswordForm.valid"
              (click)="onSubmit()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>