// core modules
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { QuillModule } from "ngx-quill";
import { DyFormFieldComponent } from "src/app/components/forms/dy-form-field/dy-form-field.component";
import { DyFormRichTextboxComponent } from "src/app/components/forms/dy-form-rich-textbox/dy-form-rich-textbox.component";
import { DyFormTextboxComponent } from "src/app/components/forms/dy-form-textbox/dy-form-textbox.component";
import { DyFormTypeaheadComponent } from "src/app/components/forms/dy-form-typeahead/dy-form-typeahead.component";
import { PageTitleComponent } from "src/app/components/page-title/page-title.component";
import { TableSortableHeader } from "src/app/directives/tablesortableheader.directives";
import { AcceptrejectManufacturerComponent } from "src/app/pages/acceptreject-manufacturer/acceptreject-manufacturer.component";
import { AddContractComponent } from "src/app/pages/add-contract/add-contract.component";
import { ConfigsComponent } from "src/app/pages/configs/configs.component";
import { ContractsComponent } from "src/app/pages/contracts/contracts.component";
import { NewManufacturerComponent } from "src/app/pages/new-manufacturer/new-manufacturer.component";
import { FilterPipe } from "../../common/customPipes/filter.pipe";
import { AddConsumerComponent } from "../../pages/add-consumer/add-consumer.component";
import { AddManufacturerComponent } from "../../pages/add-manufacturer/add-manufacturer.component";
import { AddProductComponent } from "../../pages/add-product/add-product.component";
import { AddUserComponent } from "../../pages/add-user/add-user.component";
import { ApiComponent } from "../../pages/api/api.component";
import { ConsumersComponent } from "../../pages/consumers/consumers.component";
import { DashboardComponent } from "../../pages/dashboard/dashboard.component";
import { ManufacturerComponent } from "../../pages/manufacturer/manufacturer.component";
import { PaginationComponent } from "../../pages/pagination/pagination.component";
import { UsersComponent } from "../../pages/users/users.component";
import { ViewProductsComponent } from "../../pages/view-products/view-products.component";
// project modules
import { ManagerLayoutRoutes } from "./manager-layout.routing";
import { HttpClientModule } from "@angular/common/http";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { MonthsPipe } from "src/app/common/customPipes/months.pipe";
import { NgChartsModule } from "ng2-charts";
import { OnlyNumericDirective } from "src/app/directives/only-numeric.directive";

@NgModule({
  imports: [
    CommonModule,
    QuillModule.forRoot(),
    RouterModule.forChild(ManagerLayoutRoutes),
    NgChartsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    InfiniteScrollModule,
    HttpClientModule,
    AngularEditorModule,
  ],
  declarations: [
    DashboardComponent,
    ViewProductsComponent,
    AddProductComponent,
    TableSortableHeader,
    OnlyNumericDirective,
    FilterPipe,
    ApiComponent,
    UsersComponent,
    ManufacturerComponent,
    AddManufacturerComponent,
    PaginationComponent,
    AddUserComponent,
    ConsumersComponent,
    AddConsumerComponent,
    NewManufacturerComponent,
    AcceptrejectManufacturerComponent,
    PageTitleComponent,
    ContractsComponent,
    AddContractComponent,
    ConfigsComponent,
    DyFormFieldComponent,
    DyFormTextboxComponent,
    DyFormRichTextboxComponent,
    DyFormTypeaheadComponent,
    MonthsPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [MonthsPipe],
})
export class ManagerLayoutModule {}
