<div class="d-flex justify-content-between mb-3">
  <div>
    <button id="sidebarCollapse" type="button" class="btn   rounded-pill px-4 mb-4" (click)="clickMenu()"><i
        class="fa fa-bars mr-2 side-bar-menu"></i></button>
  </div>
  <div>
    <div class="right-bar dropdown d-flex align-items-center">
      <a class="nav-link dropdown-toggle pt-0" id="navbarDropdown" role="button" data-hover="dropdown"
        data-toggle="dropdown" aria-haspopup="true" aria-ex.panded="false" (click)="showDropDown = !showDropDown">
        <img src="../../../assets/images/user-login.png">
      </a>
      <div class="mt-2">
        <p class="mb-0">You are logged in as</p>
        <p class="font-weight-light text-secondary font-size-12">
          <span>{{user?.first_name ? user.first_name : ''}} </span>
          <span>{{ user?.last_name ? user.last_name : ''}} </span>
        </p>
      </div>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="showDropDown">
        <a class="dropdown-item">Password</a>
        <a class="dropdown-item" (click)="logOut()">Logout</a>
      </div>
    </div>
  </div>
</div>