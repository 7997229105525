import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";

@Directive({
  selector: "[OnlyNumeric]",
})
export class OnlyNumericDirective {
  regexStr = "^[0-9]*$";
  constructor(private el: ElementRef, private renderer: Renderer2) {}
  // eslint-disable-next-line complexity
  @HostListener("keydown", ["$event"]) onKeyDown(event: KeyboardEvent) {
    if (
      [46, 8, 9, 27, 13, 110, 190].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.key == "a" && event.ctrlKey === true) ||
      (event.key == "a" && event.metaKey === true) ||
      // Allow: Ctrl+X
      (event.key == "x" && event.ctrlKey === true) ||
      (event.key == "x" && event.metaKey === true) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39) ||
      // Allow: num pad
      (event.keyCode >= 96 && event.keyCode <= 105) ||
      //Allow :Ctrl+C
      (event.key == "c" && event.ctrlKey === true) ||
      (event.key == "c" && event.metaKey === true) ||
      //Allow :Ctrl+V
      (event.key == "v" && event.ctrlKey === true) ||
      (event.key == "v" && event.metaKey === true)
    ) {
      // let it happen, don't do anything
      return;
    }
    return this.testNonNegativeNumberRegex(event);
  }

  testNonNegativeNumberRegex(event: KeyboardEvent) {
    const regEx = new RegExp(this.regexStr);
    if (regEx.test(event.key)) {
      return;
    } else {
      event.preventDefault();
    }
  }
  @HostListener("paste", ["$event"]) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event: KeyboardEvent) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value
        .replace(/[^0-9]/g, "")
        .replace(/\s/g, "");
      event.preventDefault();
    }, 100);
  }
}
