import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ALLOWED_ROLE } from "src/app/common/constants";
import { getFromStorage } from "src/app/common/utils";
import { isAuthenticated } from "./auth.service";


@Injectable()
export class AuthGuard  {

    constructor(
        private router: Router,
        private toastr: ToastrService,
    ) { }

    canActivate = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        let { url } = state;
        let result = getFromStorage('user');
        const checkAuthentication = await isAuthenticated();
        if (!checkAuthentication && url.startsWith('/manager/')) {
            this.toastr.error("You are not logged in. Please login to continue", "Login Error!");
            this.router.navigate(["/login"]);
            return false;
        }
        if (checkAuthentication && (url === '/' || url === '/login') && result && result.role && ALLOWED_ROLE.includes(result.role.id) ) {
            this.router.navigate(["/manager/dashboard"]);
            return false;
        }
        return true;

    };


}
