<div
  class="d-flex ml-2 mt-5 mb-5 mr-2 w-100 align-items-center justify-content-between flex-column flex-md-row mt-md-2">
  <h2 class="font-weight-bold text-capitalize">{{type}}s
  </h2>
  <div class="d-flex flex-column flex-sm-row align-items-center">

    <input *ngIf="!hideSearch" type="text" name="search" id="searchText" [(ngModel)]="searchText"
      placeholder="Quick search" (keyup.enter)="onSearchKeyUp()"
      class="pl-5 pr-3 pb-3 pt-3 font-size-18 font-weight-normal rounded border border-light ">
    <div *ngIf="!hideAddNew" class="mt-2 mt-sm-0"><button type="button"
        class="btn-primary-shadow btn font-weight-bold ml-4 pt-3 pb-3 pl-5 pr-5 font-size-18 font-style-normal text-capitalize"
        [routerLink]="['/manager/' + type]">Add
        {{type}}</button></div>
  </div>
</div>
