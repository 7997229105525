import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {
  ERROR_MESSAGES,
  FILE_TYPE,
  MAX_FILE_SIZE,
  ROLE_TYPE,
  SUCCESS_MESSAGES,
  VALIDATION_REGEX,
} from "src/app/common/constants";
import { ConfirmationModalComponent } from "src/app/components/modals/confirmation-modal/confirmation-modal.component";
import { ContractDocumentModel } from "src/app/models/contract";
import { getS3Url, removeNullOrEmpty } from "../../common/utils";
import { RegionalDataComponent } from "../../components/modals/regional-data/regional-data.component";
import { RegionalData } from "../../models/regionalData";
import { User } from "../../models/user";
import {
  addContract,
  deleteContractById,
  getBillingById,
  getContractsById,
  getManufaturer,
  getUserById,
  postUser,
  putUser,
  updateBilling,
} from "../../services/manager.service";
import {
  getMetaData,
  updateFile,
  uploadFile,
} from "../../services/product.service";
import { Country } from "./../../models/country";
import { Language } from "./../../models/language";

@Component({
  selector: "app-add-manufacturer",
  templateUrl: "./add-manufacturer.component.html",
  styleUrls: ["./add-manufacturer.component.scss"],
})
export class AddManufacturerComponent implements OnInit {
  public user: User;
  public FileType = FILE_TYPE;
  public photoURL;
  public regionalData: RegionalData[] = [];
  public detailsForm: FormGroup;
  public photo: File;
  public languages: Language[];
  public countries: Country[];
  public rootForm: FormGroup;
  public billingForm: FormGroup = this.formBuilder.group({
    is_active: [false, []],
    billing_amount: ["", [Validators.required]],
  });
  contracts: ContractDocumentModel[] = [];
  isNewManufaturer = false;
  manufaturers: { id: string | number; name: string }[] = [];
  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.detailsForm = this.formBuilder.group({
      first_name: ["", Validators.required],
      description: [""],
      manufacturer_id: ["", [Validators.required]],
      manufacturer_name: ["", []],
    });
    this.rootForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      root_ean: [""],
      contact_number: ["", Validators.pattern(VALIDATION_REGEX.CONTACT_NUMBER)],
      password: ["", Validators.pattern(VALIDATION_REGEX.COGNITO_PASSWORD)],
    });
    this.getManufaturer();
    if (this.activatedRoute.snapshot.paramMap.get("id")) {
      this.getUser(this.activatedRoute.snapshot.paramMap.get("id"));
      this.getContracts(this.activatedRoute.snapshot.paramMap.get("id"));
      this.getBilling(this.activatedRoute.snapshot.paramMap.get("id"));
    }
  }

  getUser = async (id) => {
    this.spinner.show();
    try {
      let { success, result, message } = await getUserById(id, null);
      if (success) {
        this.user = result;
        this.setFormValue();
        return;
      }
      this.toastr.error(message ? message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } catch (err) {
      this.toastr.error(
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  setFormValue = () => {
    let valuesToPatch = {};
    for (let key of Object.keys(this.user)) {
      valuesToPatch[key] = this.user[key];
    }
    this.detailsForm.patchValue(valuesToPatch);
    this.rootForm.patchValue(valuesToPatch);
    if (this.user.photo_url) this.photoURL = this.user.photo_url;
    if (this.user.regional_data) {
      this.getCountries();
    }
  };

  getCountries = async () => {
    this.spinner.show();
    try {
      this.countries = await getMetaData("country");
    } catch (err) {
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    } finally {
      this.spinner.hide();
    }
    this.getLanguages();
  };

  getLanguages = async () => {
    this.spinner.show();
    try {
      this.languages = await getMetaData("language");
    } catch (err) {
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    } finally {
      this.spinner.hide();
    }
    this.regionalData = [...this.user.regional_data];
  };

  selectFile = async (event, type) => {
    let {
      target: { files },
    } = event;
    let [file] = files;
    let [fileType] = file.type.split("/");

    if (type === "image" && fileType !== type) {
      const modalRef = this.modalService.open(ConfirmationModalComponent);
      modalRef.componentInstance.message = "Invalid File Format.";
      event.srcElement.value = null;
      return;
    }

    if (file && file.size > MAX_FILE_SIZE) {
      const modalRef = this.modalService.open(ConfirmationModalComponent);
      modalRef.componentInstance.message =
        "File too Big! Please select a file less than 100mb";
      event.srcElement.value = null;
      return;
    }

    if (file) {
      this.photo = file;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.photoURL = reader.result;
      };
    }
  };

  addRegionalData = (index = -1) => {
    const modalRef = this.modalService.open(RegionalDataComponent);
    if (index >= 0) {
      modalRef.componentInstance.data = this.regionalData[index];
    }
    modalRef.componentInstance.index = index;
    modalRef.result.then((result: any) => {
      if (result && result.success) {
        if (result.index >= 0) {
          this.regionalData[index] = result.data;
          return;
        }
        this.regionalData = [...this.regionalData, result.data];
      }
    });
  };

  deleteRegionalData = (index) => {
    this.regionalData.splice(index, 1);
  };

  public formControl(formName) {
    return this[formName].controls;
  }

  saveUser = async (putType = "user") => {
    try {
      if (this.user) {
        await this.updateUser(putType);
      } else {
        let data = {
          ...removeNullOrEmpty(this.detailsForm.value),
          ...removeNullOrEmpty(this.rootForm.value),
        };
        if (this.photo) {
          data["photo_url"] = await this.getFileUrl();
        }
        data["role_id"] = ROLE_TYPE.root;
        data.email = data.email ? data.email.toLowerCase() : "";
        this.spinner.show();
        await postUser(data);
      }
      let message = this.user
        ? SUCCESS_MESSAGES.MANU_UPDATED
        : SUCCESS_MESSAGES.MANU_CREATED;
      this.toastr.success(message);
      this.router.navigate(["/manager/manufacturers"]);
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  updateUser = async (type) => {
    const allowedParams = ["first_name", "photo_url", "description"];
    const rootParams = ["contact_number", "password"];
    let data: any = {};
    switch (type) {
      case "regional":
        let regionalData = [];
        this.user.users_manufacturers.forEach((manufaturer) => {
          this.regionalData.forEach((rg) => {
            rg.manufacturer_id = manufaturer.manufacturer_id;
            regionalData.push({ ...rg });
          });
        });
        data.regional_data = regionalData.map((rg) => {
          delete rg["user_id"];
          return removeNullOrEmpty(rg);
        });
        break;
      case "user":
        for (let key of allowedParams) {
          if (this.detailsForm.value[key]) {
            data[key] = this.detailsForm.value[key];
          }
        }
        if (this.photo && this.photoURL !== this.user.photo_url) {
          data["photo_url"] = await this.getFileUrl();
        }
        if (this.user.photo_url && !this.photo) data["photo_url"] = "";
        break;
      case "root":
        for (let key of rootParams) {
          if (this.rootForm.value[key]) {
            data[key] = this.rootForm.value[key];
          }
        }
        break;
    }
    data.id = this.user.id;
    delete data.country_id;
    this.spinner.show();
    try {
      return await putUser(data, null, null);
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  getFileUrl = async () => {
    let params = {
      file_name: this.photo.name,
      content_type: this.photo.type,
    };
    this.spinner.show();
    try {
      // get signed url from backend
      let { key, url } = (await updateFile(params)) || {};
      // upload file to signed url
      await uploadFile(url, this.photo);
      this.spinner.hide();
      // return s3 url
      return getS3Url(key);
    } catch (err) {
      this.spinner.hide();
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    }

    return null;
  };

  metadataName = (data, id) => {
    return this[data].filter((d) => d.id === id)?.[0]?.name;
  };

  addContract() {
    this.document.getElementById("contractDoc").click();
  }

  async saveContract(uploadContractUrl: string) {
    if (uploadContractUrl) {
      try {
        this.spinner.show();
        await addContract({
          manufacturer_id:
            this.activatedRoute.snapshot.paramMap.get("id") || this.user.id,
          contract_url: uploadContractUrl,
        });
        this.getContracts(
          this.activatedRoute.snapshot.paramMap.get("id") || this.user.id
        );
        this.spinner.hide();
      } catch (error) {
        this.spinner.hide();
        console.log("Error: ", error);
      }
    }
  }

  async onFileChange(event: any) {
    const file = event.target.files[0];
    let params = {
      file_name: file.name,
      content_type: file.type,
    };
    this.spinner.show();
    try {
      let { key, url } = (await updateFile(params)) || {};
      this.getBase64(file);
      await uploadFile(url, file);
      this.spinner.hide();
      (<HTMLInputElement>this.document.getElementById("contractDoc")).value =
        "";
      const uploadContractUrl = getS3Url(key);
      this.saveContract(uploadContractUrl);
    } catch (err) {
      this.spinner.hide();
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    }
  }
  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  async getContracts(id: string | number) {
    if (!id) {
      return;
    }
    try {
      this.spinner.show();
      const res = await getContractsById(id);
      this.spinner.hide();
      const sortData = res.result;
      sortData.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
      this.contracts = sortData.map((el) => ({
        ...el,
        created_at: new Date(el.created_at).toISOString(),
      }));
    } catch (error) {
      this.spinner.hide();
      console.log("Error: ", error);
    }
  }

  async deleteContract(id: string | number) {
    try {
      this.spinner.show();
      const res = await deleteContractById(id);
      this.spinner.hide();
      this.getContracts(
        this.activatedRoute.snapshot.paramMap.get("id") || this.user.id
      );
    } catch (error) {
      this.spinner.hide();
      console.log("Error: ", error);
    }
  }

  async getBilling(id) {
    try {
      this.spinner.show();
      const res = await getBillingById(id);
      this.billingForm.setValue({
        is_active: res.result.is_active,
        billing_amount: res.result.billing_amount,
      });
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.log("Error: ", error);
    }
  }

  async updateBilling() {
    if (this.billingForm.invalid) {
      return;
    }
    const { billing_amount, is_active } = this.billingForm.value;
    try {
      this.spinner.show();
      await updateBilling({
        manufacturer_id: this.user.id,
        billing_amount,
        is_active,
      });
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.log("Error: ", error);
    }
  }

  newManufaturerChange(e: any) {
    this.isNewManufaturer = e?.target?.checked ?? false;
    if (this.isNewManufaturer) {
      this.detailsForm.get("manufacturer_id").disable();
      this.detailsForm.get("manufacturer_id").patchValue("");
    } else {
      this.detailsForm.get("manufacturer_id").enable();
    }
  }
  getManufaturer = async () => {
    this.spinner.show();
    try {
      let { success, result, message } = await getManufaturer();
      if (success) {
        this.manufaturers = result;
        return;
      }
      this.toastr.error(message ? message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } catch (err) {
      this.toastr.error(
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };
}
