<div class="row">
    <div class="col-lg-5">
        <h2 class="users-design">Applicant</h2>
    </div>
</div>
<br>
<h5 class="Reg-heading font-weight-bold pl-4 mb-4">Applicant Detail</h5>

<div class="form-group outer-border p-4">
    <div class="row pt-3">
        <label class="col-md-2 col-form-label mt-0 mt-md-2">First name</label>
        <div class="col-lg-9">
            <input type="text" class="form-control form-height rounded d-flex p-2" value={{user?.first_name}}
                [readonly]="user" [ngClass]="{'disble-box':user}">
        </div>
    </div>
    <div *ngIf="user?.last_name" class="row pt-3">
        <label class="col-lg-2 col-form-label mt-0 mt-md-2">Last name</label>
        <div class="col-lg-9">
            <input type="text" class="form-control form-height rounded d-flex p-2" value={{user?.last_name}}
                [readonly]="user" [ngClass]="{'disble-box':user}">
        </div>
    </div>

    <div *ngIf="user?.manufacturer_name" class="row pt-3">
        <label class="col-lg-2 col-form-label mt-0 mt-md-2">Manufacturer name</label>
        <div class="col-lg-9">
            <input type="text" class="form-control form-height rounded d-flex p-2" value={{user?.manufacturer_name}}
                [readonly]="user" [ngClass]="{'disble-box':user}">
        </div>
    </div>

    <div *ngIf="user?.role" class="row pt-3">
        <label class="col-lg-2 col-form-label mt-0 mt-md-2">User Role</label>
        <div class="col-lg-9">
            <input type="text" class="form-control form-height rounded d-flex p-2 txtcapitalize"
                value={{user?.role?.name}} [readonly]="user" [ngClass]="{'disble-box':user}">
        </div>
    </div>

    <div *ngIf="user?.phone_number" class="row pt-3">
        <label class="col-lg-2 col-form-label mt-0 mt-md-2">Phone Number</label>
        <div class="col-lg-9">
            <input type="text" class="form-control form-height rounded d-flex p-2" value={{user?.phone_number}}
                [readonly]="user" [ngClass]="{'disble-box':user}">
        </div>
    </div>

    <div class="row pt-3">
        <label class="col-lg-2 col-form-label mt-0 mt-md-2">Email</label>
        <div class="col-lg-9">
            <input type="text" class="form-control form-height rounded d-flex p-2 wt-50" value={{user?.email}}
                [readonly]="user" [ngClass]="{'disble-box':user}">
        </div>
    </div>
    <div class="row pt-3">
        <label class="col-lg-2 col-form-label mt-0 mt-md-2">URL</label>
        <div class="col-lg-9">
            <input fid="url" type="text" class="form-control form-height rounded d-flex p-2 wt-50"
                value={{user?.website}} [readonly]="user" [ngClass]="{'disble-box':user}">
        </div>
    </div>
</div>


<div>
    <div class="mt-3 d-flex justify-content-end">
        <button type="button"
            class="font-weight-bold mb-3 rounded border border-primary btn text-dark pl-5 pb-3 pr-5 pt-3"
            (click)="deleteManu(newManuStatus.REJECT)">Reject</button>
        <button type="button" class="btn btn-primary rounded mb-3 font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
            (click)="updateManu(newManuStatus.ACCEPT)">Accept</button>

    </div>
</div>