import { AngularEditorConfig } from "@kolkov/angular-editor";

export const SUCCESS_MESSAGES = {
  LOGIN_SUCCESSFUL: "Login Successful !",
  RESET_PASSWORD: "Your password has been reset successfully.",
  PRODUCT_ADDED: "Product added successfully",
  PRODUCT_DELETED: "Product deleted successfully",
  API_DELETED: "API Bearer token deleted successfully",
  API_ADDED: "API Bearer token added successfully",
  PRODUCT_UPDATED: "Product updated successfully",
  API_UPDATED: "API Bearer token updated successfully",
  TOKEN_COPIED: "API token copied to clipboard",
  TOKEN_COPIED_ERROR: "Error in copying API token to clipboard",
  USER_CREATED: "User created successfully.",
  USER_DELETED: "User deleted successfully",
  USER_UPDATED: "User updated successfully",
  REGIONAL_DATA_UPDATED: "Regional Data updated successfully",
  MANU_CREATED: "Manufacturer created successfully.",
  MANU_DELETED: "Manufacturer deleted successfully",
  MANU_UPDATED: "Manufacturer updated successfully",
  CONSUMER_DELETED: "Consumer deleted successfully",
  CONSUMER_CREATED: "Consumer created successfully.",
  CONSUMER_UPDATED: "Consumer updated successfully.",
  PASSWORD_CHANGED: "Password changed successfully.",
  COUNTRY_CHANGED: "Country changed successfully.",
  CONTRACT_DELETED: "Contract deleted successfully",
  LANGUAGE_CHANGED: "Language changed successfully.",
  COUNTRY_LANGUAGE_MAPPING_CREATED:
    "Country Language Mapping Added successfully.",
  COUNTRY_LANGUAGE_MAPPING_UPDATED:
    "Country Language Mapping changed successfully.",
  LANGUAGE_CREATED: "Language Added successfully.",
};

export const ERROR_MESSAGES = {
  UNKNOWN_SERVER_ERR: "Something went wrong !",
  CHANGE_PASSWORD: "This password is temporary. Please change your password.",
};

export const VALIDATION_REGEX = {
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
  EAN: /^([a-zA-Z0-9_-]){13}$/,
  WEBSITE:
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
  COGNITO_PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()])[A-Za-z\d@$!%*?&#^()]{10,}$/,
  CONTACT_NUMBER: /^\+?([0-9]){6,14}[0-9]$/,
};

export const URLS = {
  USER: "/user",
  GET_PRODUCTS: "/users/products",
  GET_METADATA: "/metadata/%s",
  UPDATE_PRODUCT: "/product",
  UPDATE_FILE: "/file",
  GET_PRODUCT_BY_ID: "/product/%s",
  DELETE_PRODUCT_BY_ID: "/product/%s",
  GET_APIS: "/tokens",
  DELETE_API_BY_ID: "/token/%s",
  POST_APIS: "/token",
  USERS: "/users",
  DELETE_USER_BY_ID: "/user/%s",
  GET_USER_BY_ID: "/user/%s",
  GET_REPORTS: "/reports-new",
  SEND_CREDENTIAL: "/user/password/%s",
  GET_CONTRACTS: "/contract",
  CREATE_CONTRACT: "/contract",
  CONTRACT_BY_ID: "/contract/%s",
  MANUFACTURER_CONTRACTS: "/manufacturer/contracts",
  MANUFACTURER_BILLING: "/manufacturer/billing",
  MANUFACTURER:"/manufacturer"
};

export const todayDate = {
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  day: new Date().getDate(),
};

export const SOURCE = {
  WEB: "web",
};

export const NEW_MANUFACTURER = {
  ACCEPT: "accept",
  REJECT: "reject",
};

export const FILE_TYPE = {
  image: 2,
  video: 1,
  driver: 3,
  document: 4,
};

export const ROLE_TYPE = {
  superAdmin: 4,
  root: 1,
  admin: 2,
  writer: 3,
  consumer: 5,
};

export const ALLOWED_ROLE = [4];

export const MAX_FILE_SIZE = 104857600;

export const PAGE_COUNT = 10;
export const COUNTRY = "country";
export const LANGUAGE = "language";

export const EDITOR_CONFIG: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: "auto",
  minHeight: "150px",
  maxHeight: "auto",
  width: "auto",
  minWidth: "0",
  translate: "yes",
  enableToolbar: true,
  showToolbar: true,
  placeholder: "Enter text here...",
  defaultParagraphSeparator: "",
  defaultFontName: "",
  defaultFontSize: "",
  fonts: [
    { class: "arial", name: "Arial" },
    { class: "times-new-roman", name: "Times New Roman" },
    { class: "calibri", name: "Calibri" },
    { class: "comic-sans-ms", name: "Comic Sans MS" },
  ],
  sanitize: true,
  toolbarPosition: "top",
  toolbarHiddenButtons: [],
};
