<div class="cons-produ ml-2 mt-5 mb-5 mr-2 d-inline-block w-100">
  <h2 class="font-weight-bold">Consumers<span class="search-bx float-right">
      <input type="text" name="search" id="searchText" [(ngModel)]="searchText" placeholder="Quick search"
        (ngModelChange)="searchConsumers($event)"
        class="pl-5 pr-3 pb-3 pt-3 font-size-18 font-weight-normal rounded border border-light ">
      <span class="add-cons"><button
          class="rounded cursor-pointer font-weight-bold text-white ml-4 pt-3 pb-3 pl-5 pr-5  font-size-18 font-style-normal"
          [routerLink]="['/manager/consumer']">Add a Consumer</button></span>
    </span></h2>
</div>
<br>
<div>
  <div *ngIf=" consumerList && consumerList?.length> 0">
    <div class="table-responsive">
      <table class="table table-bordered table-fixed">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Action</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let consumer of consumerList; let i = index">
            <td>{{consumer?.first_name}}</td>
            <td>{{consumer?.email}}</td>
            <td class="cursor-pointer">
              <a class="edit-pen pl-3" [routerLink]="['/manager/consumer', consumer?.id]"> <i
                  class="fas fa-edit"></i></a>
              <a class="remove pl-3" (click)="deleteConsumer(consumer?.id)">
                <i class="fas fa-trash-alt"></i></a>
            </td>
          </tr>
        </tbody>

      </table>
    </div>
    <app-pagination [totalItems]="totalPage" [pageSize]="pageCount" [currentPage]="page" (pageClicked)="setPage($event)"
      *ngIf="totalPage > pageCount">
    </app-pagination>
  </div>
  <div *ngIf="!consumerList || consumerList?.length === 0"> No Consumer</div>
</div>
