// core modules 
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// project modules
import { LoginComponent } from "../../pages/login/login.component";
import { AuthLayoutRoutes } from "./auth-layout.routing";


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AuthLayoutRoutes),
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [LoginComponent],
})
export class AuthLayoutModule {}
