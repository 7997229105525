// core modules
import { Component, OnInit } from "@angular/core";
import { ChartConfiguration, ChartData, ChartType } from "chart.js";
import DatalabelsPlugin from "chartjs-plugin-datalabels";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ERROR_MESSAGES } from "src/app/common/constants";
import { MonthsPipe } from "src/app/common/customPipes/months.pipe";
import { getReport } from "src/app/services/manager.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  titles: string[] = ["Number of products"];
  private GREY_COLOR = "rgba(65, 74, 92)";
  public reports: any[];
  public recordTime = 11;
  private timeChange: boolean = false;

  public pieChartPlugins = [DatalabelsPlugin];

  public pieChartOptions: ChartConfiguration["options"] = {
    responsive: true,
    aspectRatio: 2,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },

    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };
  pieChartsTitles = ["All products", "Languages", "Country"];
  pieChartsHeadings = [
    "Managed Products",
    "Number Of Products",
    "Number Of Products",
  ];
  public pieChartType: ChartType = "pie";
  public pieChartsData: ChartData<"pie", number[], string | string[]>[] = [
    {
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#00B69A",
            "#FD7F00",
            "#414A5C",
            "#D80027",
            "#2E5BFF",
          ],
        },
      ],
      labels: [],
    },
    {
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#00B69A",
            "#FD7F00",
            "#414A5C",
            "#D80027",
            "#2E5BFF",
            "#FFEA88",
            "#3D9970",
            "#111111",
            "#AAAAAA",
            "#7FDBFF",
            "#B10DC9",
            "#FFDC00",
            "#001f3f",
            "#39CCCC",
            "#01FF70",
            "#85144b",
          ],
        },
      ],
      labels: [],
    },
    {
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#00B69A",
            "#FD7F00",
            "#414A5C",
            "#D80027",
            "#2E5BFF",
            "#FFEA88",
            "#3D9970",
            "#111111",
            "#AAAAAA",
            "#7FDBFF",
            "#B10DC9",
            "#FFDC00",
            "#001f3f",
            "#39CCCC",
            "#01FF70",
            "#85144b",
          ],
        },
      ],
      labels: [],
    },
  ];

  public lineChartsConfig: ChartConfiguration["data"][] = [
    {
      datasets: [
        {
          data: [],
          label: "",
          fill: 1,
          backgroundColor: this.GREY_COLOR,
          borderColor: this.GREY_COLOR,
          borderWidth: 0,
          pointBackgroundColor: this.GREY_COLOR,
          pointBorderColor: this.GREY_COLOR,
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(148,159,177,0.8)",
        },
        {
          data: [],
          label: "",
          fill: 1,
          backgroundColor: "#D80027",
          borderColor: "#D80027",
          borderWidth: 0,
          pointBackgroundColor: "#D80027",
          pointBorderColor: "#D80027",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(148,159,177,0.8)",
        },
      ],
      labels: [],
    },
  ];
  public lineChartLabels = [];

  public lineChartOptions: ChartConfiguration["options"] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {},
  };

  public lineChartLegend = true;
  public lineChartType = "line";
  public countryData = [];
  public languageData = [];
  public allProductData = [];

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private monthPipe: MonthsPipe
  ) {}

  ngOnInit(): void {
    this.getReports();
  }

  onTimeChange(chart, ev) {
    this.timeChange = true;
    this.recordTime = ev.target.value;
    this.getReports(chart);
  }

  getReports = async (chart = null) => {
    this.spinner.show();
    try {
      let { results } = await getReport();
      this.reports = results;
      this.setPieCharts();
      this.setLineCharts();
    } catch (err) {
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    } finally {
      this.spinner.hide();
    }
  };

  setLineCharts = () => {
    const lineGraphPointsData = this.getGraphPointObject();
    this.getGraphPointObject();
    this.lineChartLabels = Object.keys(lineGraphPointsData).sort((a, b) =>
      a > b ? 1 : -1
    );
    const chartDataForManualProducts = [];
    const chartDataForScrappedProducts = [];
    this.lineChartLabels.forEach((label) => {
      chartDataForManualProducts.push(
        lineGraphPointsData?.[label.toString()].manualProducts
      );
      chartDataForScrappedProducts.push(
        lineGraphPointsData?.[label.toString()].scrappedProducts
      );
    });
    this.lineChartsConfig[0].datasets[0] = {
      ...this.lineChartsConfig[0].datasets[0],
      data: chartDataForManualProducts,
      label: "Manually Added Products",
    };
    this.lineChartsConfig[0].datasets[1] = {
      ...this.lineChartsConfig[0].datasets[1],
      data: chartDataForScrappedProducts,
      label: "Scraped Products",
    };
    this.lineChartsConfig[0].labels = this.lineChartLabels.map((date) => {
      const graphDate = new Date(date.toString());
      return `${this.monthPipe
        .transform(graphDate)
        .substring(0, 3)}-${graphDate.getFullYear()}`;
    });
  };

  getGraphPointObject() {
    const obj = {};
    this.reports?.["graph"]?.forEach((element) => {
      let manualProduct = 0;
      let scrappedProduct = 0;
      if (element?.source === "web") {
        manualProduct = element.total;
      } else {
        scrappedProduct = element.total;
      }
      if (obj.hasOwnProperty(element?.formatted_date)) {
        obj[element?.formatted_date].manualProducts =
          obj[element?.formatted_date].manualProducts + manualProduct;
        obj[element?.formatted_date].scrappedProducts =
          obj[element?.formatted_date].scrappedProducts + scrappedProduct;
      } else {
        obj[element?.formatted_date] = {
          manualProducts: manualProduct,
          scrappedProducts: scrappedProduct,
        };
      }
    });
    return obj;
  }

  setPieCharts = () => {
    this.setAllProductsData(0, "all_products");
    this.setMetaDataProducts("country", 1);
    this.setMetaDataProducts("language", 2);
  };

  setAllProductsData = (index, id) => {
    this.pieChartsData[index].labels = [];
    this.pieChartsData[index].datasets[0].data = [0, 0];
    (this.reports["source"] ?? [])?.forEach((source) => {
      if (source?.source_desc === "Scrapped") {
        this.pieChartsData[index].datasets[0].data[1] += source?.total;
      } else {
        this.pieChartsData[index].datasets[0].data[0] += source?.total;
      }
    });
    let labels = [
      [
        "Manually Added".concat(
          ": ",
          this.pieChartsData[index].datasets[0].data[0].toString()
        ),
      ],
      [
        "Scrapped".concat(
          ": ",
          this.pieChartsData[index].datasets[0].data[1].toString()
        ),
      ],
    ];
    this.pieChartsData[index]["labels"] = labels;
  };

  setMetaDataProducts = async (id, index) => {
    this.pieChartsData[index].labels = [];
    this.pieChartsData[index].datasets[0].data = [];
    this.reports[id].forEach((chart) => {
      this.pieChartsData[index].labels.push(
        `${chart[id]?.name}:${chart.total}`
      );
      this.pieChartsData[index].datasets[0].data.push(chart.total);
    });
  };
}
