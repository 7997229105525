<div class="main-produ">
  <h2>Manufacturers</h2>
</div>
<h5 class="Reg-heading font-weight-bold pl-4 mb-4">Description</h5>
<div class="Reg-part border border-light rounded">
  <form
    class="form-horizontal d-inline-block w-100 p-4"
    role="form"
    [formGroup]="detailsForm"
  >
    <div class="form-group row mb-0">
      <label
        for="first_name"
        class="col-sm-2 text-dark col-form-label mt-0 mt-md-3"
        >Name</label
      >

      <div class="col-sm-7">
        <input
          type="text"
          formControlName="first_name"
          class="form-control border border-light"
          id="first_name"
          placeholder="Enter Name"
        />
        <span
          class="text-danger pt-1"
          *ngIf="
            formControl('detailsForm').first_name.touched &&
            formControl('detailsForm').first_name.errors?.required
          "
        >
          <small>Name is required.</small>
        </span>
      </div>

      <div class="film-part ml-5">
        <div
          class="film-box mt-2 mt-md-0 border border-light rounded"
          (click)="addImage.click()"
        >
          <div
            class="img-pt border border-light position-relative d-inline-block rounded h-100"
            *ngIf="photoURL"
          >
            <img [src]="photoURL" class="img-wrapper" />
            <!-- <span><a (click)="photoURL = null && photo = null"><img
                  src="../../../assets/images/close-icon.png"></a></span> -->
          </div>

          <input
            #addImage
            hidden
            type="file"
            name="file[]"
            placeholder="ADD IMAGE"
            accept="image/*"
            id="addImage"
            (change)="selectFile($event, 'image')"
          />
        </div>
        <button
          class="btn btn-primary w-140 mt-1"
          (click)="
            photoURL ? (photoURL = null && (photo = null)) : addImage.click()
          "
        >
          {{ photoURL ? "Remove Image" : "Add Image" }}
        </button>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-2 d-flex align-items-center">
        <span class="text-dark mr-2 mt-2 d-inline-block">New Manufaturer:</span>
        <span class="custom-control pt-0 custom-switch d-inline-block">
          <input
            type="checkbox"
            class="custom-control-input"
            id="isNewManufaturer"
            [value]="isNewManufaturer"
            (change)="newManufaturerChange($event)"
          />
          <label
            class="custom-control-label mt-1"
            for="isNewManufaturer"
          ></label>
        </span>
      </div>
      <div class="col-sm-9" *ngIf="isNewManufaturer">
        <label
          for="manufacturer_name"
          class="text-dark col-form-label"
          >Manufaturer Name</label
        >
        <div>
          <input
            type="text"
            formControlName="manufacturer_name"
            class="form-control border border-light"
            id="manufacturer_name"
            placeholder="Enter Name"
          />
          <span
            class="text-danger pt-1"
            *ngIf="
              formControl('detailsForm').manufacturer_name.touched &&
              !formControl('detailsForm').manufacturer_name.value &&
              isNewManufaturer
            "
          >
            <small>Manufaturer Name is required.</small>
          </span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 text-dark mt-0 mt-md-3">Select Manufaturer</label>
      <div class="col-sm-9">
        <select
          id="role_id"
          class="form-control rounded d-flex p-2 wt-50"
          formControlName="manufacturer_id"
          [ngClass]="{ 'disble-box': user }"
        >
          <option selected disabled>Select</option>
          <option *ngFor="let option of manufaturers" [value]="option.id">
            {{ option.name }}
          </option>
        </select>
        <div
          class="text-danger pt-1"
          *ngIf="
            formControl('detailsForm').manufacturer_id.touched &&
            formControl('detailsForm').manufacturer_id.errors?.required
          "
        >
          <small>Manufaturer is required. </small>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-2 text-dark mt-0 mt-md-3">Description</label>
      <div class="col-sm-10 d-flex mt-2">
        <textarea
          class="form-control desc-heig border border-light"
          rows="4"
          placeholder="Enter Description"
          formControlName="description"
          maxlength="255"
        ></textarea>
      </div>
    </div>
  </form>
  <form
    class="form-horizontal d-inline-block w-100 p-4"
    role="form"
    [formGroup]="rootForm"
  >
    <div class="form-group row mt-0">
      <label
        for="root_ean"
        class="col-sm-2 text-dark control-label mt-0 mt-md-3"
        >Root EAN</label
      >
      <div class="col-sm-9 d-flex">
        <input
          type="text"
          id="root_ean"
          placeholder=" Enter Root EAN"
          class="form-control border border-light col-sm-9 d-inline-block"
          formControlName="root_ean"
          [readonly]="user"
          [ngClass]="{ 'disble-box': user }"
        />
        <span
          class="q-mark border border-light rounded-circle text-center text-light d-inline-block m-3"
          >?</span
        >
        <div
          class="text-danger pt-3 position-absolute mt-5"
          *ngIf="
            formControl('rootForm').root_ean.touched &&
            formControl('rootForm').root_ean.errors?.pattern
          "
        >
          <small>Invalid EAN.</small>
        </div>
      </div>
    </div>
    <!-- <div class="form-group row">
      <div class="text-danger pt-1 col-sm-9"
        *ngIf="(formControl('rootForm').root_ean.touched) && formControl('rootForm').root_ean.errors?.pattern">
        <small>Invalid EAN.</small>
      </div>
    </div> -->

    <div class="form-group row">
      <label for="email" class="col-sm-2 text-dark control-label mt-0 mt-md-3"
        >Root account</label
      >
      <div class="col-sm-9 d-flex">
        <input
          type="email"
          id="email"
          placeholder="Enter Root Account"
          class="form-control border border-light col-sm-9 d-inline-block"
          formControlName="email"
          [readonly]="user"
          [ngClass]="{ 'disble-box': user }"
        />
        <span
          class="q-mark border border-light rounded-circle text-center d-inline-block m-3"
          >?</span
        >
        <span
          class="text-danger pt-3 position-absolute mt-5"
          *ngIf="
            formControl('rootForm').email.touched &&
            formControl('rootForm').email.errors?.required
          "
        >
          <small>Email is required.</small>
        </span>
        <span
          class="text-danger pt-3 position-absolute mt-5"
          *ngIf="
            formControl('rootForm').email.touched &&
            formControl('rootForm').email.errors?.email
          "
        >
          <small>Invalid Email.</small>
        </span>
      </div>
    </div>
    <div class="form-group row">
      <label
        for="contact_number"
        class="col-sm-2 text-dark control-label mt-0 mt-md-3"
      >
        Root phone number</label
      >
      <div class="col-sm-9 d-flex">
        <input
          type="text"
          id="contact_number"
          placeholder="Enter Root phone number (+1234567890)"
          class="form-control col-sm-9 d-inline-block border border-light"
          formControlName="contact_number"
        />
        <span
          class="q-mark border border-light rounded-circle text-center text-light d-inline-block m-3"
          >?</span
        >

        <div
          class="text-danger pt-3 position-absolute mt-5"
          *ngIf="
            formControl('rootForm').contact_number.touched &&
            formControl('rootForm').contact_number.errors?.pattern
          "
        >
          <small>Invalid Contact Number.</small>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label
        for="password"
        class="col-sm-2 control-label text-dark mt-0 mt-md-3"
      >
        Root password</label
      >
      <div class="col-sm-6">
        <input
          type="password"
          id="password"
          placeholder="Enter Root Password"
          class="form-control border border-light d-inline-block"
          formControlName="password"
        />
        <div
          class="text-danger pt-1"
          *ngIf="
            formControl('rootForm').password.touched &&
            formControl('rootForm').password.errors?.pattern
          "
        >
          <small
            >Password must be at least 10 characters long including at least one
            number, one special character and both lower and uppercase
            letters.</small
          >
        </div>
      </div>
      <div class="col-sm-4 mt-3 mt-md-0" *ngIf="user">
        <button
          class="btn btn-primary modi-add-nt modify_root_btn text-white ml-0 ml-md-5 font-weight-bold pl-5 pb-3 pr-5 pt-3"
          (click)="saveUser('root')"
          [disabled]="!rootForm.valid"
        >
          Modify Root Credentials
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-end form-group row">
      <button
        type="button"
        class="font-weight-bold rounded border border-primary btn text-dark pl-5 pb-3 pr-5 pt-3"
        [routerLink]="['/manager/manufacturers']"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn button_form rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
        [disabled]="
          this.user
            ? !detailsForm.valid
            : !detailsForm.valid ||
              !rootForm.valid ||
              (!formControl('detailsForm').manufacturer_name.value &&
                isNewManufaturer)
        "
        (click)="saveUser()"
      >
        Save
      </button>
    </div>
  </form>
</div>
<h5 class="Reg-heading font-weight-bold pl-4 mt-4">
  Regionalized manufacturer data
</h5>
<div class="Reg-part mt-3 border border-light rounded">
  <form class="form-horizontal d-inline-block w-100 p-4" role="form">
    <br />
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Icon</th>
            <th scope="col">Language</th>
            <th scope="col">Country</th>
            <th scope="col">Description</th>
            <th scope="col">Html Description</th>
            <th scope="col">Option</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of regionalData; let i = index">
            <td>
              <a class="edit-pen pl-3"
                ><img
                  *ngIf="data.photo_url"
                  class="regional-icon"
                  [src]="data.photo_url"
              /></a>
            </td>
            <td>{{ metadataName("languages", data.language_id) }}</td>
            <td>{{ metadataName("countries", data.country_id) }}</td>
            <td>{{ data.description }}</td>
            <td><div [innerHTML]="data.html_description"></div></td>
            <td>
              <a class="edit-pen pl-3" (click)="addRegionalData(i)"
                ><i class="fas fa-pencil-alt"></i
              ></a>
              <a class="remove pl-3" (click)="deleteRegionalData(i)"
                ><i class="fas fa-trash-alt"></i
              ></a>
            </td>
          </tr>

          <tr
            *ngIf="!regionalData || (regionalData && regionalData.length === 0)"
          >
            <td class="border-0">No Regional Data</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-end form-group row">
      <button
        type="button"
        class="btn btn-primary rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
        (click)="addRegionalData()"
        [disabled]="!user"
      >
        Add
      </button>
      <button
        type="button"
        class="btn button_form rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
        [disabled]="
          user
            ? user?.regional_data?.length === 0 && regionalData.length === 0
            : true
        "
        (click)="saveUser('regional')"
      >
        Save
      </button>
    </div>
  </form>
</div>

<h5 class="Reg-heading font-weight-bold pl-4 mt-4">Contracts</h5>
<div class="Reg-part mt-3 border border-light rounded">
  <form class="form-horizontal d-inline-block w-100 p-4" role="form">
    <br />
    <div class="table-responsive">
      <table class="table table--contracts table-bordered">
        <thead>
          <tr>
            <th scope="col" class="border-bottom-0">Upload date</th>
            <th scope="col" class="border-bottom-0 border-left-0">Action</th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngIf="contracts.length > 0; else noContractTemp"
          ></ng-container>
          <ng-container *ngFor="let contract of contracts; let i = index">
            <tr>
              <td [ngClass]="i !== 0 ? 'border-top-0' : ''">
                {{ contract.created_at | date : "yyyy-MM-dd" }}
              </td>
              <td
                [ngClass]="
                  i !== 0 ? 'border-top-0 border-left-0' : 'border-left-0'
                "
              >
                <button
                  (click)="deleteContract(contract.id)"
                  class="btn btn-primary mb-0"
                >
                  Delete
                </button>
                <a
                  [href]="contract.contract_url"
                  target="_blank"
                  class="btn btn-primary"
                  >See contract</a
                >
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-end form-group row">
      <button
        type="button"
        class="btn btn-primary rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
        [disabled]="!user"
        (click)="addContract()"
      >
        Add
      </button>
      <input
        type="file"
        id="contractDoc"
        style="display: none"
        (change)="onFileChange($event)"
        accept="application/pdf"
      />
    </div>
  </form>
</div>

<h5 class="Reg-heading font-weight-bold pl-4 mt-4">Billing</h5>
<div class="Reg-part mt-3 border border-light rounded">
  <form
    class="form-horizontal d-inline-block w-100 p-4"
    role="form"
    [formGroup]="billingForm"
  >
    <div>
      <div class="input-group input-group-sm mb-3">
        <input
          type="number"
          formControlName="billing_amount"
          OnlyNumeric
          class="form-control full-width"
          placeholder="Billing amount"
        />
      </div>
      <div class="d-flex flex-wrap justify-content-between">
        <div class="d-flex align-items-center">
          <span class="text-dark mr-2 mt-2 d-inline-block"
            >Activate Billing:</span
          >
          <span class="custom-control pt-0 custom-switch d-inline-block">
            <input
              type="checkbox"
              class="custom-control-input"
              id="enable"
              formControlName="is_active"
            />
            <label class="custom-control-label mt-1" for="enable"></label>
          </span>
        </div>

        <button
          type="button"
          class="btn btn-primary rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
          [disabled]="!user || this.billingForm.invalid"
          (click)="updateBilling()"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</div>

<ng-template #noContractTemp>
  <tr>
    <td colspan="2" class="text-center">No Contract data</td>
  </tr>
</ng-template>
