// core modules
import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { SortEvent, TableSortableHeader } from "../../directives/tablesortableheader.directives";
// project modules
import { deleteProductById, getProducts, updateProduct } from '../../services/product.service';
import { PAGE_COUNT, SUCCESS_MESSAGES, ERROR_MESSAGES } from './../../common/constants';
import { Product } from './../../models/product';
import { Subject} from 'rxjs';
import { debounceTime , distinctUntilChanged} from "rxjs/operators";

const compare = (v1: any, v2: any) => {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};

@Component({
  selector: "app-view-products",
  templateUrl: "./view-products.component.html",
  styleUrls: ["./view-products.component.scss"],
})
export class ViewProductsComponent implements OnInit {
  public products: Product[];
  searchText: string = '';
  public page: number = 1;
  public pageCount: number = PAGE_COUNT;
  public totalPage: number = 0;
  @ViewChildren(TableSortableHeader) headers: QueryList<TableSortableHeader>;
  serachProductSubject : Subject<string> = new Subject<string>();

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.loadProducts(1);
    this.serachProductSubject.pipe(debounceTime(500), distinctUntilChanged()).subscribe((searchtext)=>{
        this.loadProducts(1);
    })
  }

  loadProducts = async (page) => {
    this.page = page;
    this.spinner.show();
    try {
      let { results, totalCount } = await getProducts(this.page, PAGE_COUNT, this.searchText.trim());
      this.totalPage = totalCount;
      this.products = results;
      this.spinner.hide();
    } catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }
  }

  public onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    // sorting countries
    if (direction === "" || column === "") {
    } else {
      this.products = [...this.products].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === "asc" ? res : -res;
      });
    }
  }

  changeEnable = async (index, enable) => {
    let data = {
      id: this.products[index].id,
      enable: !enable
    }
    this.spinner.show();
    try {
      let { success } = await updateProduct(data);
      if (success) {
        let message = SUCCESS_MESSAGES.PRODUCT_UPDATED;
        this.toastr.success(message);
        this.loadProducts(this.page);
      }

    } catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }
  }

  setPage = (page) => {
    this.loadProducts(page);
  }

  deleteProduct = async (id) => {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.message = 'Are you sure you want to delete the Product?';
    modalRef.componentInstance.showCancel = true;
    modalRef.result.then((result: any) => {
      console.log("comp closed", result);
      if (result) {
        this.handleDeleteProduct(id);
      }
    });
  }

  handleDeleteProduct = async (id) => {
    console.log(id);
    this.spinner.show();
    try {
      let { success } = await deleteProductById(id);
      if (success) {
        this.loadProducts(1)
        this.toastr.success(SUCCESS_MESSAGES.PRODUCT_DELETED);
      }
    } catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }
  }

  searchProject(event){
    this.serachProductSubject.next(event)
  }
}
