// core modules
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ConfirmationModalComponent } from "src/app/components/modals/confirmation-modal/confirmation-modal.component";
import { DomSanitizer } from "@angular/platform-browser";
// product modules
import {
  EDITOR_CONFIG,
  FILE_TYPE,
  SOURCE,
  todayDate,
  VALIDATION_REGEX,
} from "../../common/constants";
import { convertMSToNgbDate, getS3Url, removeNull } from "../../common/utils";
import { Caracteristic } from "../../models/Caracteristic";
import { Country } from "../../models/country";
import { Language } from "../../models/language";
import {
  getMetaData,
  getProductById,
  updateFile,
  updateProduct,
  uploadFile,
} from "../../services/product.service";
import {
  ERROR_MESSAGES,
  MAX_FILE_SIZE,
  SUCCESS_MESSAGES,
} from "./../../common/constants";
import { File } from "./../../models/file";
import { Product } from "./../../models/product";
import { TagModel } from "src/app/models/tags";
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: "app-add-product",
  templateUrl: "./add-product.component.html",
  styleUrls: ["./add-product.component.scss"],
})
export class AddProductComponent implements OnInit {
  public product: Product;
  public detailsForm: FormGroup;
  readonly today: NgbDateStruct = todayDate;
  public countries: Country[];
  public languages: Language[];
  public files: File[] = [];
  public caracteristics: Caracteristic[] = [];
  public FileType = FILE_TYPE;
  public activeDocs: any;
  public activeEdit: number = -1;
  public urlInput: any;
  public tagForm: FormGroup;
  public tags: TagModel[] = [];
  public activeTagEdit: number = -1;
  editorConfig: AngularEditorConfig = EDITOR_CONFIG;
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private router: Router,
    private _sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.detailsForm = this.formBuilder.group({
      ean: [
        "",
        [Validators.required, Validators.pattern(VALIDATION_REGEX.EAN)],
      ],
      date: [null, [Validators.required]],
      enable: [true],
      title: ["", Validators.required],
      description: [""],
      country_id: [null, Validators.required],
      manufacturer_name: [null, Validators.required],
      language_id: [null],
      manufacturer_reference: [null],
      asin: [""],
      html_description:[""],
    });
    this.getCountries();
    this.getLanguages();
    this.setActiveDocs();
    if (this.activatedRoute.snapshot.paramMap.get("id")) {
      this.getProduct(this.activatedRoute.snapshot.paramMap.get("id"));
    }
    this.initTagForm();
  }

  getProduct = async (id) => {
    this.spinner.show();
    try {
      this.product = await getProductById(id);
      this.setProduct();
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  setActiveDocs = () => {
    this.activeDocs = {
      document: {
        file: null,
        name: null,
        description: null,
      },
      driver: {
        file: null,
        name: null,
        description: null,
      },
      char: {
        caracteristique: null,
        description: null,
      },
    };
  };

  addProduct = async () => {
    let data = this.detailsForm.value;
    if (data.date) {
      data.date = new Date(
        data.date.year,
        data.date.month - 1,
        data.date.day
      ).valueOf();
    }
    data.source = SOURCE.WEB;
    if (this.files && this.files.length > 0)
      data.files = this.getProductFiles();
    else data.files = [];
    if (this.caracteristics && this.caracteristics.length > 0)
      data.caracteristics = this.caracteristics;
    else data.caracteristics = [];
    data.tags = this.tags;
    data = removeNull(data);
    if (this.product && this.product.id) data.id = this.product.id;
    this.spinner.show();
    try {
      let { success } = await updateProduct(data);
      if (success) {
        let message =
          this.product && this.product.id
            ? SUCCESS_MESSAGES.PRODUCT_UPDATED
            : SUCCESS_MESSAGES.PRODUCT_ADDED;
        this.toastr.success(message);
        this.router.navigate(["/manager/products"]);
      }
    } catch (err) {
      this.toastr.error(err.message);
    } finally {
      this.spinner.hide();
    }
  };

  formControl(formName) {
    return this[formName].controls;
  }

  getCountries = async () => {
    this.spinner.show();
    try {
      this.countries = await getMetaData("country");
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  getLanguages = async () => {
    this.spinner.show();
    try {
      this.languages = await getMetaData("language");
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  doCancel = () => {
    this.router.navigate(["/manager/products"]);
  };

  selectFile = async (event, type) => {
    let {
      target: { files },
    } = event;
    let [file] = files;
    let [fileType] = file.type.split("/");

    if ((type === "image" || type === "video") && fileType !== type) {
      const modalRef = this.modalService.open(ConfirmationModalComponent);
      modalRef.componentInstance.message = "Invalid File Format.";
      event.srcElement.value = null;
      return;
    }

    if (file && file.size > MAX_FILE_SIZE) {
      const modalRef = this.modalService.open(ConfirmationModalComponent);
      modalRef.componentInstance.message =
        "File too Big! Please select a file less than 100mb";
      event.srcElement.value = null;
      this.activeDocs[type].description = "";
      return;
    }

    if (file) {
      switch (FILE_TYPE[type]) {
        case FILE_TYPE.image:
          return this.uploadFileToS3(file, type);
        case FILE_TYPE.video:
          return this.uploadFileToS3(file, type);
        case FILE_TYPE.document:
          return this.addDoc(file, type);
        case FILE_TYPE.driver:
          return this.addDoc(file, type);
      }
    }
  };

  addDoc = (file, type) => {
    this.activeDocs[type].file = file;
    this.activeDocs[type].name = file.name;
  };

  uploadDoc = (type) => {
    this.uploadFileToS3(this.activeDocs[type].file, type);
  };

  uploadFileToS3 = (file, type) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (event) => {
      let fl = new File();
      fl.buffer_url = reader.result;
      fl.type_id = FILE_TYPE[type];
      if (
        FILE_TYPE[type] === FILE_TYPE.driver ||
        FILE_TYPE[type] === FILE_TYPE.document
      ) {
        fl.description = this.activeDocs[type].description;
        this.resetActiveDoc(type);
      }
      fl.url = await this.getFileUrl(file);
      if (fl.url) this.addFile(fl);
    };
  };

  addFile = (file: File) => {
    this.files.push(file);
  };

  resetActiveDoc = (type) => {
    for (let key of Object.keys(this.activeDocs[type])) {
      this.activeDocs[type][key] = null;
    }
  };

  removeFile = (file) => {
    let index;
    this.files.forEach((fl, i) => {
      if (fl.url === file.url) index = i;
    });
    this.files.splice(index, 1);
  };

  getFileUrl = async (file) => {
    let params = {
      file_name: file.name,
      content_type: file.type,
    };
    this.spinner.show();
    try {
      // get signed url from backend
      let { key, url } = (await updateFile(params)) || {};
      // upload file to signed url
      await uploadFile(url, file);
      this.spinner.hide();
      // return s3 url
      return getS3Url(key);
    } catch (err) {
      this.spinner.hide();
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    }
    return null;
  };

  getProductFiles = () => {
    let files = [];
    for (let type of Object.keys(FILE_TYPE)) {
      let fls = this.files.filter((file) => file.type_id === FILE_TYPE[type]);
      fls.map((fl, index) => {
        fl.order = index + 1;
        delete fl.buffer_url;
        delete fl.external_url;
      });
      files = [...fls, ...files];
    }
    return files;
  };

  addChars = () => {
    let char = new Caracteristic();
    char.caracteristique = this.activeDocs.char.caracteristique;
    char.description = this.activeDocs.char.description;
    if (this.activeEdit >= 0) {
      this.caracteristics[this.activeEdit] = char;
      this.activeEdit = -1;
    } else {
      this.caracteristics.push(char);
    }
    this.resetActiveDoc("char");
  };

  removeChar = (index) => {
    this.caracteristics.splice(index, 1);
  };

  editChar = (index) => {
    this.activeEdit = index;
    this.activeDocs.char = { ...[...this.caracteristics][index] };
  };

  setProduct = () => {
    const valuesToPatch = [
      "ean",
      "date",
      "enable",
      "title",
      "description",
      "country_id",
      "language_id",
      "asin",
      "manufacturer_reference",
      "manufacturer",
      "html_description",
    ];
    for (let val of valuesToPatch) {
      switch (val) {
        case "date":
          this.patchFormValue(val, convertMSToNgbDate(this.product.date));
          break;
        case "country_id":
          this.patchFormValue(val, this.product.country.id);
          break;
        case "language_id":
          if (this.product.language_id) {
            this.patchFormValue(val, this.product.language_id);
          }
          break;
        case "manufacturer":
          if (this.product.manufacturer && this.product.manufacturer.name) {
            this.patchFormValue(
              "manufacturer_name",
              this.product.manufacturer.name
            );
          }
          break;
        default:
          this.patchFormValue(val);
          break;
      }
    }

    const productFiles = ["caracteristics", "files"];
    for (let file of productFiles) {
      if (this.product[file] && this.product[file].length > 0) {
        this[file] = this.product[file];
        for (let fl of this[file]) {
          if (fl.product_id) delete fl.product_id;
          if (fl.file_type) delete fl.file_type;
          if (
            file === "files" &&
            fl.type_id &&
            fl.type_id === FILE_TYPE.video
          ) {
            let sanUrl = this.getSanatizedUrl(fl.url);
            if (sanUrl) fl.external_url = sanUrl;
          }
        }
      }
    }
    this.tags = this.product.tag.map((item) => ({
      tag_name: item.tag_name,
    }));
  };

  patchFormValue = (key, value = null) => {
    let patch = {};
    patch[key] = value ? value : this.product[key];
    this.detailsForm.patchValue(patch);
  };

  addExternalUrl(type) {
    if (
      !this.urlInput.match(
        /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
      )
    ) {
      const modalRef = this.modalService.open(ConfirmationModalComponent);
      modalRef.componentInstance.message =
        "Invalid URL, Please enter a valid youtube/vimeo url.";
    } else {
      //console.log(urlInput, typeof urlInput);
      let fl = new File();
      fl.url = this.urlInput;
      fl.external_url = this.getSanatizedUrl(this.urlInput);
      fl.type_id = FILE_TYPE[type];
      this.addFile(fl);
    }
    this.urlInput = null;
    //(document.getElementById(urlInput) as HTMLFormElement).reset();
    //this.isDisable = true;
  }

  getSanatizedUrl(url) {
    if (url.search("youtu") > 0) return this.getYouTubeUrl(url);
    if (url.search("vimeo") > 0) return this.getVimeoUrl(url);
    return null;
  }

  getYouTubeUrl(ytUrl: string) {
    var regExp =
      /^.*((youtube\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = ytUrl.match(regExp);
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/".concat(match[7])
    );
  }

  getVimeoUrl(vUrl: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      "https://player.vimeo.com/video/".concat(
        /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(vUrl)[3]
      )
    );
  }
  initTagForm() {
    this.tagForm = this.formBuilder.group({
      tagName: ["", Validators.required],
    });
  }
  editTag(index: number) {
    this.activeTagEdit = index;
    this.tagForm?.get("tagName")?.patchValue(this.tags[index].tag_name);
  }
  removeTag(index: number) {
    this.tags.splice(index, 1);
  }
  addTag() {
    if (this.activeTagEdit >= 0) {
      this.tags[this.activeTagEdit] = {
        ...this.tags[this.activeTagEdit],
        tag_name: this.tagForm?.get("tagName")?.value ?? "",
      };
      this.tagForm?.get("tagName")?.patchValue("");
      this.activeTagEdit = -1;
    } else {
      this.tags.push({ tag_name: this.tagForm?.get("tagName")?.value ?? "" });
      this.tagForm?.get("tagName")?.patchValue("");
    }
  }
}
