import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { PAGE_COUNT, ROLE_TYPE, SUCCESS_MESSAGES, ERROR_MESSAGES } from 'src/app/common/constants';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { User } from 'src/app/models/user';
import { deleteUserById, getUsers, sendCredentialById } from 'src/app/services/manager.service';
import { SortEvent, TableSortableHeader } from "../../directives/tablesortableheader.directives";
const compare = (v1: any, v2: any) => {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};

@Component({
  selector: "app-manufacturer",
  templateUrl: "./manufacturer.component.html",
  styleUrls: ["./manufacturer.component.scss"],
})
export class ManufacturerComponent implements OnInit {
  public ManuList: User[];
  public RoleType = ROLE_TYPE;
  public searchText: string = "";
  public user: User;
  public page: number = 1;
  public pageCount: number = PAGE_COUNT;
  public totalPage: number = 0;
  @ViewChildren(TableSortableHeader) headers: QueryList<TableSortableHeader>;

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getManufacturer(1);
  }

  getManufacturer = async (page) => {
    this.page = page;
    this.spinner.show();
    try {
      let { results, totalCount } = await getUsers(
        ROLE_TYPE.root,
        this.searchText.trim(),
        null,
        this.page,
        this.pageCount,
        null
      );
      this.totalPage = totalCount;
      this.ManuList = results;
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  public onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    if (direction === "" || column === "") {
    } else {
      this.ManuList = [...this.ManuList].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === "asc" ? res : -res;
      });
    }
  }

  setPage = (page) => {
    this.getManufacturer(page);
  };

  deleteManu = async (id) => {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.message =
      "Are you sure you want to delete the Manufacturer?";
    modalRef.componentInstance.showCancel = true;
    modalRef.result.then((result: any) => {
      console.log("comp closed", result);
      if (result) {
        this.handleDeleteManu(id);
      }
    });
  };

  handleDeleteManu = async (id) => {
    this.spinner.show();
    try {
      let { success } = await deleteUserById(id);
      if (success) {
        this.toastr.success(SUCCESS_MESSAGES.MANU_DELETED);
        this.getManufacturer(1);
      }
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };
  sendCredential=async (id:string)=>{
    this.spinner.show();
    try {
      let { success } = await sendCredentialById(id);
      if (success) {
        let message = SUCCESS_MESSAGES.PASSWORD_CHANGED;
        this.toastr.success(message);
      }
    } catch (err) {
      this.toastr.error(
        err && err.message
          ? err.message.message
            ? err.message.message
            : err.message
          : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  }
}
