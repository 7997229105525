import { Auth } from "aws-amplify";
import { ERROR_MESSAGES } from "../../common/constants";

export const SignIn = async (email, password) => {
  let res = {
    result: null,
    message: null,
  };
  try {
    res.result = await Auth.signIn(email, password);
    console.log(await Auth.currentCredentials());
  } catch (err) {
    res.message = err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
  }
  return res;
};

export const completeNewPassword = async (user, password) => {
  let res = {
    result: null,
    message: null,
  };
  try {
    res.result = await Auth.completeNewPassword(user, password, {});
  } catch (err) {
    res.message = err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
  }
  return res;
};

export const SignOut = async () => {
  return await Auth.signOut();
};

export const isAuthenticated = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (err) {
    return false;
  }
};
