import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ERROR_MESSAGES, ROLE_TYPE, PAGE_COUNT, SUCCESS_MESSAGES, NEW_MANUFACTURER } from 'src/app/common/constants';
import { User } from 'src/app/models/user';
import { getUsers, putUser } from 'src/app/services/manager.service';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
@Component({
  selector: 'app-new-manufacturer',
  templateUrl: './new-manufacturer.component.html',
  styleUrls: ['./new-manufacturer.component.scss']
})
export class NewManufacturerComponent implements OnInit {
  public ManuList: User[] = [];
  public page: number = 0;
  public pageCount: number = PAGE_COUNT;
  public newManuStatus = NEW_MANUFACTURER;
  public totalPage: number = 0;
  public paginationToken: string;
  public throttle = 300;
  public scrollDistance = 1;
  public scrollUpDistance = 2;
  public isUpdate: boolean = false;
  constructor(private router: Router, private spinner: NgxSpinnerService, private modalService: NgbModal, private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getNewManufacturer();
  }

  onScroll() {
    console.log('scrolled!!');
    if (this.totalPage > this.page * this.pageCount) this.getNewManufacturer();
  }

  getNewManufacturer = async (showSpinner = true) => {
    this.page++;
    if (showSpinner) this.spinner.show();
    try {

      if (this.isUpdate) {
        this.page = 1;
        this.paginationToken = '';
        this.isUpdate = false;
        this.ManuList = [];
      }

      let { results, totalCount } = await getUsers(null, null, "cognito_unconfirmed", this.page, this.pageCount, this.paginationToken);
      this.totalPage = totalCount;
      if (results.users) {
        this.showManufacturer(results.users);
      }
      if (results.paginationToken)
        this.paginationToken = results.paginationToken;
    } catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }
  }

  updateManu = async (id, status) => {
    this.spinner.show();
    try {
      await putUser(null, id, status);
      let message: string = "";
      if (status === this.newManuStatus.ACCEPT)
        message = SUCCESS_MESSAGES.USER_CREATED;
      if (status === this.newManuStatus.REJECT)
        message = SUCCESS_MESSAGES.USER_DELETED;
      this.toastr.success(message);
      this.isUpdate = true
      this.getNewManufacturer();
    } catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }
  }

  deleteManu = async (id, status) => {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.message = 'Are you sure you want to delete the user?';
    modalRef.componentInstance.showCancel = true;
    modalRef.result.then((result: any) => {
      if (result) {
        this.updateManu(id, status);
      }
    });
  }

  showManufacturer(manuLists) {
    if (manuLists.length > 0)
      this.ManuList = this.ManuList.concat(manuLists);
  }
  
  public mapRoles = {
    root: 'HUB',
    consumer: 'PLUG'
  }

}
