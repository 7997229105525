<div class="row">
  <div class="col-sm-5">
    <h2 class="api-design font-weight-bold font-style-normal position-static">API</h2>
  </div>
  <div class="col-sm-3">

  </div>
  <div class="col-sm-4">
    <button type="button" class="btn btn-primary rounded font-weight-bold font-style-normal btn-block api-btn"
      (click)="addApi = true">Generate a new
      key</button>
  </div>
</div>

<br>
<div *ngIf="!addApi">
  <div class="table-responsive " *ngIf="apis && apis?.length > 0">
    <table class="table table-bordered table-fixed">
      <thead>
        <tr>
          <th scope="col">API Bearer token</th>
          <th scope="col">Token Comment</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let a of apis">
          <td>{{a?.token}}</td>
          <td>{{a?.comments}}</td>
          <td class=" cursor-pointer">
            <a class="edit-pen pl-3" (click)="editToken(a)"><i class="fas fa-pencil-alt"></i></a>
            <a class="copy pl-3" (click)="copyToken(a?.token)"><i class="far fa-clone"></i></a>
            <a class="remove pl-3" (click)="deleteToken(a?.id)"><i class="fas fa-trash-alt"></i></a>

          </td>
        </tr>

      </tbody>
    </table>
  </div>
  <!-- <div> TODO ADD NO API TOEKN HERE</div> -->
  <div *ngIf="!apis || apis?.length === 0"> You do not have any tokens. Click on Generate a new key to create a token
  </div>
</div>


<div *ngIf="addApi">
  <div class="descr-part">
    <h2 class="pt-2 pl-2 pr-2 m-3 font-weight-bold font-size-18">Description</h2>

    <form [formGroup]="detailsForm" class="pt-0 pl-3 w-100 border border-light d-inline-block pb-5 rounded">
      <div class="form-group mb-0 mt-5 w-100 float-left font-size-17">
        <div class="row pl-3 ">
          <label class="col-sm-1 text-dark col-form-label mt-0 mt-md-3">Comment</label>
          <div class="col-sm-11">
            <input formControlName="comments" id="comments" type="text"
              class="form-control border border-light rounded d-flex p-2 wt-50" placeholder="Enter Comment">
          </div>
        </div>

      </div>
    </form>
  </div>

  <div class="mt-3 d-flex justify-content-end">
    <button type="button" class="font-weight-bold rounded border border-primary btn text-dark pl-5 pb-3 pr-5 pt-3"
      (click)="addApi = false">Cancel</button>
    <button type="button" class="btn btn-primary rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
      [disabled]="!detailsForm.valid" (click)="addToken()">Save</button>

  </div>
  <!-- <div class="api-part btn-div">

    <div class="api-tl p-0 border-0">
      <form class="api-form">

        <div class="form-horizontal">
          <div class="form-group">
            <div class="mt-3 d-flex justify-content-end">
              <div>
                <button class="btn cancel-api-btn" (click)="addApi = false">Cancel</button>
              </div>
              <div>
                <button class="btn btn-primary save-api-btn" [disabled]="!detailsForm.valid" (click)="addToken()">
                  Save </button>
              </div>
            </div>

          </div>



        </div>

      </form>



    </div>

  </div> -->

</div>
