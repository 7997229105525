<!-- <div class="row">
  <div class="col-sm-5">
    <h2 class="font-weight-bold font-style-normal position-static">Users</h2>
  </div>
  <div class="col-sm-3">
    <input type="text" name="search" id="searchText" [(ngModel)]="searchText" placeholder="Quick search"
    (ngModelChange)="searchUsers($event)"
    class="pl-5 pr-3 pb-3 pt-3 font-size-18 font-weight-normal rounded border border-light ">
  </div>
  <div class="col-sm-4">
    <button type="button" class="btn btn-primary rounded font-weight-bold font-style-normal btn-block users-btn "
      [routerLink]="['/manager/user']">Add a user</button>
  </div>
</div> -->
<div class="cons-produ ml-2 mt-5 mb-5 mr-2 d-inline-block w-100">
  <h2 class="font-weight-bold">Users<span class="search-bx float-right">
      <input type="text" name="search" id="searchText" [(ngModel)]="searchText" placeholder="Quick search"
        (ngModelChange)="searchUsers($event)"
        class="pl-5 pr-3 pb-3 pt-3 font-size-18 font-weight-normal rounded border border-light ">
      <span class="add-cons"><button
          class="rounded cursor-pointer font-weight-bold text-white ml-4 pt-3 pb-3 pl-5 pr-5  font-size-18 font-style-normal"
          [routerLink]="['/manager/user']">Add a user</button></span>
    </span></h2>
</div>
<br>
<div>
  <div *ngIf="userList && userList.length > 0">
    <div class="table-responsive outer-table-users" *ngIf="userList && userList.length > 0">
      <table class="table table-bordered table-fixed">


        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Action</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of userList">

            <td>{{user?.first_name}} {{user?.last_name? user.last_name : ''}}</td>
            <td>{{user?.email}}</td>
            <td class="cursor-pointer">
              <a class="edit-pen pl-3" [routerLink]="['/manager/user', user?.id]"><i class="fas fa-edit"></i></a>
              <a class="remove pl-3" (click)="deleteUser(user?.id)"><i class="fas fa-trash-alt"></i></a>
            </td>
          </tr>


        </tbody>

      </table>
    </div>
    <!-- </div> -->
    <app-pagination [totalItems]="totalPage" [pageSize]="pageCount" (pageClicked)="setPage($event)" [currentPage]="page"
      *ngIf="totalPage > pageCount">
    </app-pagination>
  </div>
  <div *ngIf="!userList || userList.length === 0">
    No Users
  </div>
</div>
