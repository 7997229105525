<app-page-title type="contract" hideSearch="true"></app-page-title>
<div class="form-content">
  <h5 class="font-weight-bold pl-4 mb-4">Details</h5>
  <form [formGroup]="detailsForm" class="d-flex w-100 flex-column form-app">

    <div class="form-group" *ngFor="let formField of formFields;">
      <dy-form-field [formGroup]="detailsForm" [formField]="formField" [search]="searchUser"></dy-form-field>
    </div>

  </form>

  <div class="form-group">
    <div class="mt-3 d-flex justify-content-end">
      <button type="button" class="font-weight-bold rounded border border-primary btn text-dark pl-5 pb-3 pr-5 pt-3"
        [routerLink]="['/manager/contracts']">Cancel</button>
      <button type="button" class="btn btn-primary rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
        [disabled]="!detailsForm.valid" (click)="upsert()">Save</button>

    </div>
  </div>
</div>
