import { FieldBase } from "./field-base";

export class TextboxField extends FieldBase<string> {
  controlType = "textbox";
  type: string;
  maxlength?: number;
  minlength?: number;
  max?: number;
  min?: number;
  readonly?: boolean;

  placeholder?: string;
  unique?: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options["type"] || "";
    if (options["maxlength"]) {
      this.maxlength = options["maxlength"];
    }
    if (options["minlength"]) {
      this.minlength = options["minlength"];
    }
    if (options["max"]) {
      this.max = options["max"];
    }
    if (options["min"]) {
      this.min = options["min"];
    }
    if (options["unique"]) {
      this.unique = options["unique"];
    }
    if (options["readonly"]) {
      this.readonly = options["readonly"];
    }
    this.placeholder = options["placeholder"] || "";
  }
}
