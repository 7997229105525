import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { User } from 'src/app/models/user';
import { getUserById, putUser } from 'src/app/services/manager.service';
import { ERROR_MESSAGES, NEW_MANUFACTURER, SUCCESS_MESSAGES } from 'src/app/common/constants';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-acceptreject-manufacturer',
  templateUrl: './acceptreject-manufacturer.component.html',
  styleUrls: ['./acceptreject-manufacturer.component.scss']
})
export class AcceptrejectManufacturerComponent implements OnInit {
  public user: any;
  public newManuStatus = NEW_MANUFACTURER;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    if (this.activatedRoute.snapshot.paramMap.get('id')) {
      this.getUser(this.activatedRoute.snapshot.paramMap.get('id'));
    }
  }

  getUser = async (id) => {
    this.spinner.show();
    try {
      let { success, result, message } = await getUserById(id, "cognito_unconfirmed");
      if (success) {
        this.user = result;
        return;
      }
      this.toastr.error(message ? message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } catch (err) {
      this.toastr.error(err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }
  }

  updateManu = async (status) => {
    this.spinner.show();
    try {
      await putUser(null, this.user.user_id, status);
      let message: string = "";
      if (status === this.newManuStatus.ACCEPT)
        message = SUCCESS_MESSAGES.USER_CREATED;
      if (status === this.newManuStatus.REJECT)
        message = SUCCESS_MESSAGES.USER_DELETED;
      this.toastr.success(message);
      this.router.navigate(["/manager/newmanufacturer"]);
    } catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }
  }

  deleteManu = async (status) => {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.message = 'Are you sure you want to delete the user?';
    modalRef.componentInstance.showCancel = true;
    modalRef.result.then((result: any) => {
      if (result) {
        this.updateManu(status);
      }
    });
  }
}
