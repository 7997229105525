// core modules
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { QuillModule } from "ngx-quill";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from "./app.component";
// project modules
import { AppRoutingModule } from "./app.routing.module";
import { ComponentsModule } from "./components/component.module";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { AuthLayoutModule } from "./layouts/auth-layout/auth-layout.module";
import { ManagerLayoutComponent } from "./layouts/manager-layout/manager-layout.component";
import { ManagerLayoutModule } from "./layouts/manager-layout/manager-layout.module";
import { AuthGuard } from "./services/auth/auth.guard";
@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    AppRoutingModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AuthLayoutModule,
    ToastrModule.forRoot(),
    QuillModule.forRoot(),
    ComponentsModule,
    ManagerLayoutModule,
  ],
  declarations: [AppComponent, AuthLayoutComponent, ManagerLayoutComponent],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
