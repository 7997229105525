// core modules
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { VERSION } from "./../../../environments/version";

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class?: string;
  iconClass?: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: "dashboard",
    title: "Dashboard",
    icon: "pie-chart.svg",
    class: "",
  },
  {
    path: "products",
    title: "Products",
    icon: null,
    iconClass: "fas fa-star pl-2",
    class: "",
  },
  {
    path: "manufacturers",
    title: "Manufacturer",
    icon: "warehouses.svg",
    class: "",
  },
  {
    path: "configs",
    title: "Config",
    icon: null,
    iconClass: "fa fa-cog pl-2",
  },
  {
    path: "contracts",
    title: "Contracts",
    icon: "warehouses.svg",
    class: "",
  },
  {
    path: "consumers",
    title: "Consumer",
    icon: null,
    iconClass: "fas fa-shopping-cart pl-2",
  },
  {
    path: "api",
    title: "API",
    icon: "api.svg",
    class: "",
  },
  {
    path: "users",
    title: "Users",
    icon: "user.svg",
    class: "",
  },
  {
    path: "newmanufacturer",
    title: "Applicants",
    icon: "applicant.svg",
    class: "",
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.component.scss"],
})
export class SideBarComponent implements OnInit {
  public menuItems: any[];
  public ver = VERSION.version;
  @Input() showSideNav: boolean;
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }
}
