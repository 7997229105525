import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { HeaderComponent } from "./app-header/app-header.component";
import { ConfirmationModalComponent } from "./modals/confirmation-modal/confirmation-modal.component";
import { RegionalDataComponent } from "./modals/regional-data/regional-data.component";
import { SideBarComponent } from "./side-nav/side-bar.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        AngularEditorModule,
    ],
    declarations: [
        SideBarComponent,
        HeaderComponent,
        ConfirmationModalComponent,
        RegionalDataComponent,
    ],
    exports: [SideBarComponent, HeaderComponent]
})
export class ComponentsModule {}
