import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldBase } from "../models/field-base";

@Component({
  selector: "dy-form-rich-textbox",
  templateUrl: "./dy-form-rich-textbox.component.html",
  styleUrls: ["./dy-form-rich-textbox.component.scss"],
})
export class DyFormRichTextboxComponent implements OnInit {
  @Input() formField: FieldBase<any>;
  @Input() formGroup: FormGroup;
  constructor() {}

  ngOnInit(): void {}
  get formControl() {
    return this.formGroup.controls;
  }

  public editorOptions = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ["clean"],
      ["link"],
    ],
  };
}
