import { Routes } from "@angular/router";
import { AcceptrejectManufacturerComponent } from "src/app/pages/acceptreject-manufacturer/acceptreject-manufacturer.component";
import { AddContractComponent } from "src/app/pages/add-contract/add-contract.component";
import { AddManufacturerComponent } from "src/app/pages/add-manufacturer/add-manufacturer.component";
import { ConfigsComponent } from "src/app/pages/configs/configs.component";
import { ContractsComponent } from "src/app/pages/contracts/contracts.component";
import { NewManufacturerComponent } from "src/app/pages/new-manufacturer/new-manufacturer.component";
import { AuthGuard } from "src/app/services/auth/auth.guard";
import { AddConsumerComponent } from "../../pages/add-consumer/add-consumer.component";
import { AddProductComponent } from "../../pages/add-product/add-product.component";
import { ApiComponent } from "../../pages/api/api.component";
import { ConsumersComponent } from "../../pages/consumers/consumers.component";
import { DashboardComponent } from "../../pages/dashboard/dashboard.component";
import { ManufacturerComponent } from "../../pages/manufacturer/manufacturer.component";
import { UsersComponent } from "../../pages/users/users.component";
import { ViewProductsComponent } from "../../pages/view-products/view-products.component";
import { AddUserComponent } from "./../../pages/add-user/add-user.component";

export const ManagerLayoutRoutes: Routes = [
  { path: "", component: DashboardComponent },
  { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard] },
  { path: "products", component: ViewProductsComponent , canActivate: [AuthGuard]},
  { path: "product/:id", component: AddProductComponent , canActivate: [AuthGuard]},
  { path: "product", component: AddProductComponent , canActivate: [AuthGuard]},
  { path: "api", component: ApiComponent , canActivate: [AuthGuard]},
  { path: "users", component: UsersComponent , canActivate: [AuthGuard]},
  { path: "user", component: AddUserComponent , canActivate: [AuthGuard] },
  { path: "user/:id", component: AddUserComponent , canActivate: [AuthGuard]},
  { path: "manufacturers", component: ManufacturerComponent , canActivate: [AuthGuard]},
  { path: "manufacturer", component: AddManufacturerComponent , canActivate: [AuthGuard]},
  { path: "manufacturer/:id", component: AddManufacturerComponent , canActivate: [AuthGuard]},
  { path: "consumers", component: ConsumersComponent, canActivate: [AuthGuard] },
  { path: "consumer", component: AddConsumerComponent, canActivate: [AuthGuard] },
  { path: "consumer/:id", component: AddConsumerComponent , canActivate: [AuthGuard]},
  { path: "newmanufacturer", component: NewManufacturerComponent , canActivate: [AuthGuard]},
  { path: "newmanufacturer/:id", component: AcceptrejectManufacturerComponent , canActivate: [AuthGuard]},
  { path: "contracts", component: ContractsComponent, canActivate: [AuthGuard] },
  { path: "contract", component: AddContractComponent , canActivate: [AuthGuard]},
  { path: "contract/:id", component: AddContractComponent , canActivate: [AuthGuard]},
  { path: "configs", component: ConfigsComponent, canActivate: [AuthGuard] },
];
