import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldBase } from "../models/field-base";

@Component({
  selector: "dy-form-field",
  templateUrl: "./dy-form-field.component.html",
  styleUrls: ["./dy-form-field.component.scss"],
})
export class DyFormFieldComponent implements OnInit {
  @Input() formField: FieldBase<any>;
  @Input() formGroup: FormGroup;
  @Input() search: any;
  constructor() {}

  ngOnInit(): void {}
}
