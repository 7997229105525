<app-page-title type="config" hideSearch="true" hideAddNew="true"></app-page-title>
<!-- <div>
  <div class="d-flex align-items-center justify-content-between">
    <h5>Countries</h5>
    <input type='text' [(ngModel)]="searchModel" class="form-control border border-light mb-2 col-3" placeholder="Search" (ngModelChange)="searchCountryByText()"/>
  </div>
   <div *ngIf="countries?.length> 0">
    <div class="table-responsive outer-table-manu">
      <div class="force-overflow"></div>
      <div class="table-scroll">
      <table class="table table-striped header-fixed table-bordered table-zeus">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Code</th>
            <th scope="col">Enabled</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let country of countryFilterArray; let i = index">

            <td>{{country?.name}}
            </td>
            <td>{{country?.code}}</td>
            <td>
              <div class="custom-switch-control custom-switch">
                <input type="checkbox" class="custom-control-input" [id]="i" [checked]="country?.enable"
                  (change)="changeEnable(i, country?.enable)" />
                <label class="custom-control-label" [for]="i"></label>
              </div>
            </td>

          </tr>

        </tbody>
      </table>
      </div>
    </div>


  </div>
  <div *ngIf="!countryFilterArray || countryFilterArray?.length === 0">No Countries</div>
</div> -->



<!-- <div class="mt-3">
  <div class="heading mt-3 mb-3">
     <h5>Languages</h5>
       <button type="button" class="btn btn-primary" (click)="displayLanguageForm()">ADD</button>
  </div>
  <div *ngIf="languages?.length> 0">
    <div class="table-responsive outer-table-manu">
      <div class="force-overflow"></div>
      <table class="table table-bordered table-fixed table-zeus">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Code</th>
            <th scope="col">Enabled</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let language of languages; let i = index">

            <td>{{language?.name}}
            </td>
            <td>{{language?.code}}</td>
            <td>
              <div class="custom-switch-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="{{'lang'+i}}" [checked]="language?.enable"
                  (change)="changeEnableLanguage(i, language?.enable)" />
                <label class="custom-control-label" for="{{'lang'+i}}"></label>
              </div>
            </td>

          </tr>

        </tbody>
      </table>
    </div>
<div class="dialog" *ngIf="displayAddLanguageForm">
   <div  class="dialog-inner">
    <span (click)="closeLanguageForm()">
      <img class="cross" src="assets/images/close-icon.png"></span>
        <form  [formGroup]="languageForm">
            <input type="text" placeholder="Language" formControlName="language_name" class="form-control border border-light mb-3">
            <input type="text" placeholder="Code" formControlName="language_code" class="form-control border border-light mb-3">
            <div class="submit-button">
              <button type="button" class="btn btn-primary" [disabled]="languageForm.invalid" (click)="addLanguage()">ADD LANGUAGE</button>
              <button type="button" class="btn btn-primary" (click)="closeLanguageForm()">CANCEL</button>
            </div>
        </form>
    </div>
</div>
  </div>
  <div *ngIf="!languages || languages?.length === 0">No Languages</div>
</div> -->

<div class="mt-3">
  <div class="heading mt-3 mb-3">
      <h5>Country Language Mapping</h5>
      <button type="button" class="btn btn-primary" (click)="displayMappingForm()">ADD</button>
  </div>

     <div *ngIf="countryLanguageMapping?.length> 0">
    <div class="table-responsive outer-table-manu">
      <div class="force-overflow"></div>
      <table class="table table-bordered table-fixed table-zeus">
        <thead>
          <tr>
            <th scope="col">Country</th>
             <th scope="col">Country Code</th>
            <th scope="col">Language</th>
            <th scope="col">Language Code</th>
            <th scope="col">Enabled</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let countryLanguageMapp of countryLanguageMapping; let i = index">
            <td>{{ countryMap[countryLanguageMapp?.country_id]||'' }}
            </td>
             <td>{{ countryLanguageMapp?.country?.code||'' }}
            </td>
            <td>{{ languageMap[countryLanguageMapp?.language_id]||''}}</td>
              <td>{{ countryLanguageMapp?.language?.code||'' }}
            </td>
            <td>
              <div class="custom-switch-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="{{'countryLangMapp'+i}}" [checked]="countryLanguageMapp?.enable"
                  (change)="changeEnableCountryLanguageMapping(i, countryLanguageMapp?.enable)" />
                <label class="custom-control-label" for="{{'countryLangMapp'+i}}"></label>
              </div>
            </td>
            <td> <a class="edit-pen pl-3" (click)="displayMappingForm(countryLanguageMapp?.country_id,countryLanguageMapp?.language_id,countryLanguageMapp?.id)"><i class="fas fa-pencil-alt"></i></a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

    <div class="dialog" *ngIf="displayAddMappingForm">
   <div  class="dialog-inner">
    <span (click)="closeMappingForm()">
      <img class="cross" src="assets/images/close-icon.png"></span>
        <form  [formGroup]="countryLanguageMapForm">
        <select formControlName="country_id"class="form-control border border-light">
          <option disabled selected>Select</option>
          <ng-container *ngFor="let country of enabledCountries">
            <option [value]="country?.id">{{country?.name}}{{country?.code?' - '+country?.code:''}}</option>
          </ng-container>
        </select>

        <select formControlName="language_id" class="form-control border border-light">
          <option disabled selected>Select</option>
          <ng-container *ngFor="let language of languages">
            <option [value]="language?.id">{{language?.name}}{{language?.code?' - '+language?.code:''}}</option>
          </ng-container>
        </select>
        <div class="submit-button">
                  <button type="button" class="btn btn-primary" [disabled]="countryLanguageMapForm.invalid" (click)="updateCountryLanguageMapping()">{{actionType.EDIT===mappingFormActionType ?'EDIT': 'ADD'}} MAPPING</button>

        <button type="button" class="btn btn-primary" (click)="closeMappingForm()">CANCEL</button>
        </div>

        </form>
      </div>

  <div *ngIf="!countryLanguageMapping || countryLanguageMapping?.length === 0">No Country Language Mapping</div>
</div>
</div>