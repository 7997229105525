import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "src/app/common/constants";
import { Country } from "src/app/models/country";
import { CountryLanguageMap } from "src/app/models/country-language-mapping";
import { Language } from "src/app/models/language";
import { getMetaData, updateMetaData } from "src/app/services/product.service";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";

enum ActionType{
  ADD='add',
  EDIT="edit"
}
@Component({
  selector: "app-configs",
  templateUrl: "./configs.component.html",
  styleUrls: ["./configs.component.scss"],
})
export class ConfigsComponent implements OnInit {
  public countries: Country[] = [];
  public languages: Language[] = [];
  public languageMap = {};
  public countryMap = {};
  public countryLanguageMapping: CountryLanguageMap[] = [];
  public displayAddMappingForm = false;
  public enabledCountries: Country[] = [];
  countryLanguageMapForm!: FormGroup;
  searchModel: any;
  searchCountrySubject$ = new BehaviorSubject<string>("");
  countryFilterArray: Country[] = [...this.countries];
  public displayAddLanguageForm = false;
  languageForm!: FormGroup;
  mappingFormActionType: ActionType = ActionType.ADD;
  actionType=ActionType;
  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    this.loadCountries();
    this.loadLanguages();
    this.loadCountryLanguageMapping();
    this.subscribeToSearchCountrySubject();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.countryLanguageMapForm = this.fb.group({
      language_id: ["", [Validators.required]],
      country_id: ["", [Validators.required]],
      id:['']
    });

    this.languageForm = this.fb.group({
      language_name: ["", [Validators.required]],
      language_code: ["", [Validators.required]],
    });
  }

  async loadCountries() {
    this.spinner.show();
    try {
      this.countries = await getMetaData("country");
      this.enabledCountries = this.countries.filter((country) => {
        return country?.enable ?? false;
      });
      if (this.searchCountrySubject$.value) {
        this.searchCountrySubject$.next(this.searchCountrySubject$.value);
      } else {
        this.countryFilterArray = this.countries;
      }
      this.createCountryMap();
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  }

  changeEnable = async (index, enable) => {
    let data = {
      id: this.countryFilterArray[index].id,
      enable: !enable,
    };
    this.spinner.show();
    try {
      let { success } = await updateMetaData("country", data);
      if (success) {
        this.toastr.success(SUCCESS_MESSAGES.COUNTRY_CHANGED);
        this.loadCountries();
      }
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  loadLanguages = async () => {
    this.spinner.show();
    try {
      this.languages = await getMetaData("language");
      this.createLanguageMap();
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  changeEnableLanguage = async (index, enable) => {
    let data = {
      id: this.languages[index].id,
      enable: !enable,
    };
    this.spinner.show();
    try {
      let { success } = await updateMetaData("language", data);
      if (success) {
        this.toastr.success(SUCCESS_MESSAGES.LANGUAGE_CHANGED);
        this.loadLanguages();
      }
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };
  displayMappingForm(country_id?: number, language_id?: number,id?:number|string) {
    this.displayAddMappingForm = true;
    if(country_id&&language_id){
      this.mappingFormActionType=ActionType.EDIT;
    }
    this.countryLanguageMapForm.patchValue({
      language_id: language_id ?? this.languages[0].id,
      country_id: country_id ?? this.countries[0].id,
      id:id
    });
  }

  closeMappingForm() {
    this.displayAddMappingForm = false;
  }

  async updateCountryLanguageMapping() {
    let data = {
      country_id: +this.countryLanguageMapForm.controls["country_id"].value,
      language_id: +this.countryLanguageMapForm.controls["language_id"].value,
      enable: true,
      ...(this.countryLanguageMapForm.controls["id"].value
        ? { id: +this.countryLanguageMapForm.controls["id"].value }
        : {}),
    };
    this.spinner.show();
    try {
      let { success } = await updateMetaData("country-language", data);
      if (success) {
        this.toastr.success(SUCCESS_MESSAGES.COUNTRY_LANGUAGE_MAPPING_CREATED);
        this.loadCountryLanguageMapping();
      }
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
    this.closeMappingForm();
  }

  async loadCountryLanguageMapping() {
    this.spinner.show();
    try {
      this.countryLanguageMapping = await getMetaData("country-language");
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  }

  async changeEnableCountryLanguageMapping(index: number, enable: boolean) {
    let data = {
      country_id: +this.countryLanguageMapping[index].country_id,
      language_id: +this.countryLanguageMapping[index].language_id,
      enable: !enable,
    };
    this.spinner.show();
    try {
      let { success } = await updateMetaData("country-language", data);
      if (success) {
        this.toastr.success(SUCCESS_MESSAGES.COUNTRY_LANGUAGE_MAPPING_UPDATED);
        this.loadCountryLanguageMapping();
      }
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  }

  createLanguageMap() {
    this.languages?.forEach((lang) => {
      this.languageMap[lang.id] = lang.name;
    });
  }

  createCountryMap() {
    this.countries?.forEach((country) => {
      this.countryMap[country.id] = country.name;
    });
  }

  searchCountryByText() {
    this.searchCountrySubject$.next(this.searchModel);
  }

  subscribeToSearchCountrySubject() {
    this.searchCountrySubject$
      .pipe(distinctUntilChanged(), debounceTime(300))
      .subscribe((data) => {
        this.filterCountry(data);
      });
  }

  filterCountry(searchText: string) {
    if (searchText) {
      this.countryFilterArray = this.countries.filter((country) => {
        return (
          country?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
          country?.code?.toLowerCase().includes(searchText.toLowerCase())
        );
      });
    } else {
      this.countryFilterArray = [...this.countries];
    }
  }

  displayLanguageForm() {
    this.displayAddLanguageForm = true;
    this.languageForm.patchValue({
      language_code: "",
      language_name: "",
    });
  }

  async addLanguage() {
    let data = {
      code: this.languageForm.controls["language_code"].value,
      name: this.languageForm.controls["language_name"].value,
      enable: true,
    };
    console.log(data);
    this.spinner.show();
    try {
      let { success } = await updateMetaData("language", data);
      if (success) {
        this.toastr.success(SUCCESS_MESSAGES.LANGUAGE_CREATED);
        this.loadLanguages();
      }
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
    this.closeLanguageForm();
  }

  closeLanguageForm() {
    this.displayAddLanguageForm = false;
  }
}
