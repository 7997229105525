<div class="cons-produ  mt-4 mb-5 d-inline-block w-100">
  <h2 class="font-weight-bold text-dark">Consumer</h2>
</div>
<h5 class="font-weight-bold pl-4 mb-4 text-dark">Consumer Data</h5>

<div class="cons-part border border-light rounded">
  <form class="form-horizontal d-inline-block w-100 p-4" [formGroup]="detailsForm">
    <div class="row">
      <div class="col-lg-9 order-2 order-lg-1">


        <div class="form-group row mt-0">
          <label class="col-lg-3 text-dark control-label ml-2 ml-md-0 mt-3 mt-md-3">Name</label>
          <div class="col-lg-9 ">
            <input formControlName="first_name" id="first_name" type="text" placeholder="Enter Name"
              class="form-control border border-light col-sm-9 d-inline-block text-dark">
            <div class="text-danger pt-1" *ngIf="
                    formControl.first_name.touched &&
                    formControl.first_name.errors?.required">
              <small>Name is required. </small>
            </div>
          </div>
        </div>

        <div class="form-group row mt-0">
          <label class="col-lg-3 text-dark control-label mt-0 mt-md-3">Phone number</label>
          <div class="col-lg-9 ">
            <input formControlName="contact_number" id="contact_number" type="text" placeholder="Enter phone number"
              class="form-control border border-light col-sm-9 d-inline-block text-dark">
            <div class="text-danger pt-1" *ngIf="
                  formControl.contact_number.touched &&
                  formControl.contact_number.errors?.pattern">
              <small>Invalid Contact Number. </small>
            </div>
          </div>
        </div>
        <div class="form-group row mt-0">
          <label class="col-lg-3 text-dark control-label mt-0 mt-md-3">Mail address</label>
          <div class="col-lg-9 ">
            <input formControlName="email" id="email" type="email" placeholder="Enter mail address"
              class="form-control border border-light col-sm-9 d-inline-block text-dark" [readonly]="user"
              [ngClass]="{'disble-box':user}">

            <div class="text-danger pt-1" *ngIf="
                formControl.email.touched &&
                formControl.email.errors?.required">
              <small>Email is required. </small>
            </div>
            <div class="text-danger pt-1" *ngIf="
                formControl.email.touched &&
                formControl.email.errors?.email
                ">
              <small>Invalid Email. </small>
            </div>
          </div>
        </div>
        <div class="form-group row mt-0">
          <label class="col-lg-3 text-dark control-label mt-0 mt-md-3">URL</label>
          <div class="col-lg-9 ">
            <input formControlName="url" id="url" type="text" placeholder="Enter url"
              class="form-control border border-light col-sm-9 d-inline-block text-dark">
            <div class="text-danger pt-1" *ngIf="
                    formControl.url.touched &&
                    formControl.url.errors?.required">
              <small>URL is required. </small>
            </div>
            <div class="text-danger pt-1" *ngIf="
                    formControl.url.touched &&
                    formControl.url.errors?.pattern">
              <small>Invalid URL. </small>
            </div>
          </div>
        </div>


      </div>
      <div class="col-lg-3 order-1 order-lg-2 pl-0">
        <div class="film-part ml-4 ml-lg-0 mt-0">

          <div class="film-box mt-2 mt-md-0 border border-light rounded" (click)="addImage.click()">

            <div class="img-pt border border-light position-relative d-inline-block rounded h-100" *ngIf="photoURL">

              <img [src]="photoURL" class="img-wrapper">

            </div>

            <input #addImage hidden type="file" name="file[]" placeholder="ADD IMAGE" accept="image/*" id="addImage"
              (change)="selectFile($event, 'image')" />
          </div>
          <button class="btn btn-primary w-140 mt-1"
            (click)="photoURL ? photoURL = null && photo = null : addImage.click()">{{photoURL ? 'Remove Image' : 'Add Image'}}</button>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group row">
          <label class="col-lg-2 text-dark mt-0 mt-md-3  h-100 pt-1">Description</label>
          <div class="col-lg-9 mt-2 ml-0 ml-md-3 desc">
            <textarea class="form-control desc-heig border border-light text-dark h-100 p-2" rows="4"
              placeholder="Enter Description" id="description" formControlName="description" maxlength="255"></textarea>
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <div class="form-group w-100 float-left mb-0 mt-4">
          <div class="row pl-2">
            <label class="col-lg-3 mt-0 mt-md-3 text-dark col-form-label">Language</label>
            <div class="col-lg-5 d-flex pl-1">
              <select id="language_id" formControlName="language_id" class="form-control border border-light">
                <option *ngFor="let lan of languages" [value]="lan.id">{{lan.name}}</option>
              </select>
              <span
                class="q-mark text-light border border-light d-inline-block m-3 rounded-circle text-center pr-2 pr-md-0 pl-2 pl-md-0 position-relative">?</span>
            </div>
          </div>
        </div>
        <div class="form-group w-100 float-left mb-0 mt-4">
          <div class="row pl-2">
            <label class="col-6 col-md-0 col-lg-3 mt-0 mt-md-3 text-dark col-form-label">Country</label>
            <div class="col-lg-5 pl-1">
              <div class="d-flex">
                <select id="country_id" class="form-control border border-light" formControlName="country_id">

                  <option *ngFor="let country of countries" [value]="country.id">{{country.name}} </option>

                </select>
                <span
                  class="q-mark text-light border border-light d-inline-block m-3 rounded-circle text-center pr-2 pr-md-0 pl-2 pl-md-0 position-relative">?</span>
              </div>

              <div class="text-danger pt-1" *ngIf="
                formControl.country_id.touched &&
                formControl.country_id.errors?.required
                  ">
                <small>Country is required. </small>
              </div>

            </div>
          </div>
        </div>

        <div class="form-group w-100 float-left mb-0 mt-4">

          <div class="row pl-2">
            <label class="col-3  col-lg-3 text-dark col-form-label mt-3">Enable</label>
            <div class="col-3 col-lg-3 pl-1 mt-2 ml-2 ml-lg-0 mt-lg-0">
              <span class="custom-control mt-2 custom-switch d-inline-block">
                <input type="checkbox" class="custom-control-input" id="enable" formControlName="enable" />
                <label class="custom-control-label mt-1" for="enable"></label>
              </span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>
  <div class=" d-flex justify-content-end ">
    <div class="row form-group m-3">
      <button type="button"
        class="font-weight-bold button_form  font-size-18 rounded border border-primary mt-1 btn text-white pl-5 pb-3 pr-5 pt-3"
        [disabled]="!user" (click)="sendCredential()">Send
        Credentials</button>
      <button type="button"
        class="font-weight-bold font-size-18 rounded border border-primary  mt-1 btn text-dark pl-5 pb-3 pr-5 pt-3"
        [routerLink]="['/manager/consumers']">Cancel</button>
      <button type="button"
        class="btn button_form rounded font-size-18 font-weight-bold mt-1 text-white pl-5 pb-3 pr-5 pt-3"
        [disabled]="!detailsForm.valid" (click)="createUser()">Save</button>
    </div>
  </div>

</div>