<div class="main-produ ml-2  d-inline-block w-100">
    <h2 class="font-weight-bold">Applicants</h2>
</div>
<div>
    <br>
    <div *ngIf="ManuList && ManuList?.length> 0">

        <div class="table-responsive outer-table-manu search-results" infiniteScroll [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false">
            <div class="force-overflow"></div>
            <table class="table table-bordered table-fixed">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">User Role</th>
                        <th scope="col">Application</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let manu of ManuList; let i = index">
                        <td class="cursor-pointer" [routerLink]="['/manager/newmanufacturer', manu?.user_id]">
                            {{manu?.name}}
                        </td>
                        <td class="cursor-pointer" [routerLink]="['/manager/newmanufacturer', manu?.user_id]">
                            {{manu?.email}}
                        </td>
                        <td class="cursor-pointer txtcapitalize"
                            [routerLink]="['/manager/newmanufacturer', manu?.user_id]">
                            {{manu?.role.name}}
                        </td>

                        <td class="cursor-pointer txtcapitalize"
                            [routerLink]="['/manager/newmanufacturer', manu?.user_id]">
                            {{mapRoles[manu?.role.name]}}
                        </td>

                        <td class="cursor-pointer d-flex">
                            <div class="font-style">
                                <span class="pl-3  fas fa-check"
                                    (click)="updateManu(manu?.user_id, newManuStatus.ACCEPT)"></span>
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <div class="color-red">
                                <span class="fa  fa-times-circle"
                                    (click)="deleteManu(manu?.user_id, newManuStatus.REJECT)"></span>
                            </div>
                        </td>

                    </tr>

                </tbody>
            </table>

        </div>
    </div>
    <div *ngIf="!ManuList || ManuList?.length === 0"> No Applicant</div>
</div>