import { FormControl } from "@angular/forms";

export class FieldBase<T> {
  value: T;
  key: string;
  label: string;
  description?: "string";
  required: boolean;
  display: string;
  order: number;
  controlType: string;
  id: string;
  callback: Function;
  disabled?: boolean;
  formControl: FormControl;

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      display?: string;
      order?: number;
      controlType?: string;
      id?: string;
      callback?: Function;
      fxFlex?: string;
      disabled?: boolean;
    } = {}
  ) {
    if (options.id) {
      this.id = options.id;
    }
    if (options.callback) {
      this.callback = options.callback;
    }
    if (options.callback) {
      this.callback = options.callback;
    }
    if (options.disabled) {
      this.disabled = options.disabled;
    }
    this.description = options["description"] || undefined;
    this.value = options.value;
    this.key = options.key || "";
    this.label = options.label || "";
    this.required = !!options.required;
    this.display = options.display || "";
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || "";
  }
}
