import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { defer } from "rxjs";
import { ERROR_MESSAGES } from "src/app/common/constants";
import { RichTextboxField } from "src/app/components/forms/models/field-rich-textbox";
import { TextboxField } from "src/app/components/forms/models/field-textbox";
import { TypeAheadField } from "src/app/components/forms/models/field-typeahead";
import {
  createContract,
  getById,
  updateContract,
} from "src/app/services/contract.service";
import { getUsers } from "src/app/services/manager.service";

@Component({
  selector: "app-add-contract",
  templateUrl: "./add-contract.component.html",
  styleUrls: ["./add-contract.component.scss"],
})
export class AddContractComponent implements OnInit {
  public detailsForm: FormGroup;
  public formFields: any[] = [];
  public contract: any;

  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.detailsForm = this.formBuilder.group({
      contract_title: ["", Validators.required],
      contract_description: ["", Validators.required],
      user: ["", Validators.required],
    });
    this.formFields = [
      new TypeAheadField({
        key: "user",
        label: "User",
        placeholder: "Search User..",
      }),
      new TextboxField({
        key: "contract_title",
        label: "Title",
        placeholder: "Enter Title",
      }),
      new RichTextboxField({
        key: "contract_description",
        label: "Description",
        placeholder: "Enter Description",
      }),
    ];
    if (this.activatedRoute.snapshot.paramMap.get("id")) {
      this.loadById(this.activatedRoute.snapshot.paramMap.get("id"));
    }
  }

  loadById = async (id) => {
    this.spinner.show();
    try {
      let { success, result } = await getById(id);
      if (success) {
        this.contract = result;
        const nonEditable = ["user"];
        for (let field of nonEditable) {
          this.detailsForm.controls[field].disable();
        }

        const fieldsToPatch = ["contract_title", "contract_description"];
        let patch = {};
        for (let field of fieldsToPatch) {
          if (field in this.contract) patch[field] = this.contract[field];
        }
        if (this.contract.user) {
          patch["user"] = {
            name: this.contract.user.id,
            value: this.getNameofUser(this.contract.user),
          };
        }
        this.detailsForm.patchValue(patch);
      }
    } catch (err) {
      this.toastr.error(
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  getNameofUser = (r) => {
    let names = [];
    if (r.first_name) {
      names.push(r.first_name);
    }
    if (r.last_name) {
      names.push(r.last_name);
    }
    return names.join(" ");
  };

  searchUser = (searchText: string) => {
    return defer(() => {
      return getUsers([1, 5], searchText, null, null, null, null).then(
        (results) => {
          if (results && results.results) {
            return results.results.map((r) => {
              return { name: r.id, value: this.getNameofUser(r) };
            });
          }
          return [];
        }
      );
    });
  };
  ngOnInit(): void {}

  async upsert() {
    if (this.contract) {
      // we need to update
      this.update();
    } else {
      this.create();
    }
  }

  async update() {
    this.spinner.show();
    try {
      await updateContract({
        ...this.detailsForm.getRawValue(),
        id: this.contract.id,
      });
      this.toastr.success("Contract updated successfully");
      this.router.navigate(["/manager/contracts"]);
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  }

  async create() {
    this.spinner.show();
    try {
      await createContract(this.detailsForm.value);
      this.toastr.success("Contract added successfully");
      this.router.navigate(["/manager/contracts"]);
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  }
}
