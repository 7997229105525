// core modules
const util = require("util");

// project modules
import { URLS } from "./../common/constants";
import { call, uploadFileToS3 } from "./backend.service";

export const getProducts = async (page, count, ean = null) => {
  let queryParams = {
    page: page,
    limit: count,
  };
  if (ean) queryParams["ean"] = ean;
  let result = await call(URLS.GET_PRODUCTS, "get", null, queryParams);
  if (result.success) {
    return result;
  }
};

export const getMetaData = async (type) => {
  const url = util.format(URLS.GET_METADATA, type);
  let { success, results } = await call(url, "get");
  if (success) {
    return results;
  }
};

export const updateMetaData = async (type, params) => {
  const url = util.format(URLS.GET_METADATA, type);
  return call(url, "put", params);
};

export const updateProduct = async (params) => {
  return await call(URLS.UPDATE_PRODUCT, "put", params);
};

export const updateFile = async (params) => {
  return await call(URLS.UPDATE_FILE, "put", params);
};

export const uploadFile = async (url, file) => {
  return await uploadFileToS3(url, file);
};

export const getProductById = async (id) => {
  const url = util.format(URLS.GET_PRODUCT_BY_ID, id);
  let { success, result } = await call(url, "get");
  if (success) return result;
};

export const deleteProductById = async (id) => {
  const url = util.format(URLS.DELETE_PRODUCT_BY_ID, id);
  return await call(url, "del");
};
