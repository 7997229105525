<div class="main-produ">
  <h2>Product <span class="search-bx">
      <!-- <span><a>Add a
                    product</a></span> -->
    </span></h2>

</div>
<div class="descr-part">

  <h2 class="font-weight-bold mb-3 pl-4 font-size-18">Description</h2>

  <form [formGroup]="detailsForm" class="d-inline-block rounded border border-light p-3">

    <div class="form-horizontal">

      <div class="form-group w-100 float-left mb-0 mt-4">
        <div class="row pl-3">
          <label class="col-lg-1 mt-0 mt-md-3 text-secondary col-form-label">EAN</label>
          <div class="col-lg-11 d-flex box-size">
            <input formControlName="ean" id="ean" type="text"
              class="form-control border border-light rounded d-flex p-2 mr-3 " placeholder="Enter EAN">
              <img ngbPopover="The EAN Code is a type of barcode that encodes an article number" triggers="mouseenter:mouseleave" src="../../../assets/images/q-mark.png" class="q-mark ml-4 mt-3" >
          </div>
          <div class="offset-lg-1 col-lg-11">
            <div class="text-danger pt-1 " *ngIf="
                  formControl('detailsForm').ean.touched &&
                  formControl('detailsForm').ean.errors?.required
                    ">
              <small>EAN is required. </small>
            </div>
            <div class="text-danger pt-1 " *ngIf="
                  formControl('detailsForm').ean.touched &&
                  formControl('detailsForm').ean.errors?.pattern
                    ">
              <small>Invalid EAN. </small>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group w-100 float-left mb-0 mt-4">

        <div class="row pl-3">
          <div class="col-lg-6 col-xs-12 pl-0">
            <label class="col-lg-2 text-secondary mt-0 mt-md-3 ellipses col-form-label">Publishing Date</label>
            <div class="col-lg-10 d-flex pl-0 pl-md-3">
              <input class="form-control  border border-light" placeholder="YYYY-MM-DD" name="dp" ngbDatepicker
                [minDate]="product && product?.id ? null: today" #d="ngbDatepicker" formControlName="date">
              <img src="../../../assets/images/calu-icon.png" (click)="d.toggle()"
                class="cul-img ml-4 mt-3 cursor-pointer">
              <img ngbPopover="Publishing Date" triggers="mouseenter:mouseleave" src="../../../assets/images/q-mark.png" class="q-mark ml-4 mt-3" >
            </div>
            <div>
              <span class="text-danger pt-1 d-block mt-2" *ngIf="
                            formControl('detailsForm').date.touched &&
                            formControl('detailsForm').date.errors?.required
                                ">
                <small class="ml-0 ml-md-3">Publishing Date is required. </small>
              </span>

              <span class="text-danger pt-1 d-block mt-2" *ngIf="
                            formControl('detailsForm').date.touched &&
                            formControl('detailsForm').date.errors && !formControl('detailsForm').date.errors?.required
                                ">
                <small class="ml-0 ml-md-3">Invalid Date. </small>
              </span>
            </div>
          </div>
          <div class="col-lg-6 col-xs-12 pl-0">
            <label class="col-sm-3 text-secondary col-form-label mt-3 ">Enable</label>
            <div class="col-sm-9">
              <span class="custom-control mt-2 custom-switch d-inline-block">
                <input type="checkbox" class="custom-control-input" id="enable" formControlName="enable" />
                <label class="custom-control-label mt-1" for="enable"></label>
              </span>

              <img src="../../../assets/images/q-mark.png" class="q-mark ml-4 pr-2 pr-md-2">
            </div>
          </div>

        </div>

      </div>


      <div class="form-group w-100 float-left mb-0 mt-4">
        <div class="row pl-3">

          <label class="col-lg-1 text-secondary mt-0 mt-md-3 col-form-label ">Title</label>
          <div class="col-lg-11 box-size">

            <input type="text" class="form-control  border border-light mr-3" placeholder="Enter Title"
              formControlName="title">
            <span class="text-danger pt-1" *ngIf="
                  formControl('detailsForm').title.touched &&
                  formControl('detailsForm').title.errors?.required
                    ">
              <small>Title is required. </small>
            </span>
          </div>
        </div>
      </div>

      <div class="form-group w-100 float-left mb-0 mt-4">


        <label class="text-secondary mt-0 mt-md-3">Description</label>

        <textarea class="form-control  border border-light desc-heig h-100 p-3" rows="4" formControlName="description"
          placeholder="Enter Description"></textarea>
      </div>

      <div class="form-group w-100 float-left mb-0 mt-4">
        <div class="row pl-3 pr-3">
                  <label class="text-secondary mt-0 mt-md-3">Html Description</label>
        <angular-editor  [config]="editorConfig" formControlName="html_description"></angular-editor>
        </div>
      </div>     
      
      <div class="form-group w-100 float-left mb-0 mt-4">
        <div class="row pl-3">
          <div class="col-lg-6 col-sm-12 d-flex pl-0  pl-md-0">
            <label class="col-6 col-md-0 col-lg-3 mt-0 mt-md-3 text-secondary col-form-label">Language</label>

            <select id="language_id" class="form-control border border-light" formControlName="language_id">

              <option *ngFor="let lan of languages" [value]="lan.id">{{lan.name}}</option>

            </select>
            <span
              class="q-mark  text-light border border-light d-inline-block m-3 pr-2 pr-md-0 pl-2 pl-md-0 rounded-circle text-center position-relative">?</span>
          </div>
          <div class="col-lg-6 col-sm-12 d-flex mt-4 mt-lg-0 pl-0 pl-md-0 ">
            <label class="mt-0 mt-md-3 text-secondary mt-3 mt-md-0 col-form-label d-inline-block">Manufacturer
              reference</label>
            <input type="text" class="form-control border border-light " placeholder="Enter Manufacturer Reference"
              formControlName="manufacturer_reference">


          </div>
        </div>

      </div>

      <div class="form-group w-100 float-left mb-0 mt-4">
        <div class="row pl-3">
          <div class="col-lg-6 col-sm-12 d-flex pl-0  pl-md-0">
            <label class="col-6 col-md-0 col-lg-3 mt-0 mt-md-3 text-secondary col-form-label">Country</label>

            <select id="country_id" class="form-control border border-light" formControlName="country_id">

              <option *ngFor="let country of countries" [value]="country.id">{{country.name}}</option>

            </select>
            <span
              class="q-mark text-light border border-light d-inline-block m-3 rounded-circle text-center pr-2 pr-md-0 pl-2 pl-md-0 position-relative">?</span>

          </div>
          <div class="col-lg-6 col-sm-12 d-flex mt-4 mt-lg-0 pl-0 pl-md-0 ">
            <label class="mt-0 mt-md-3 text-secondary mt-3 mt-md-0 col-form-label d-inline-block">ASIN</label>
            <input type="text" class="form-control border border-light " placeholder="Enter ASIN"
              formControlName="asin">
          </div>

        </div>
      </div>
    </div>
    <!-- New changes-->
    <div class="form-group w-100 float-left mb-0 mt-4">
      <div class="row pl-3">
        <div class="col-lg-6 col-sm-12 d-flex  pl-0  pl-md-0">
          <label class="col-6 col-md-0 col-lg-3 mt-0 mt-md-3 text-secondary ellipses col-form-label">Manufacturer
            Name</label>
          <input id="manufacturer_name" type="text" class="form-control border border-light"
            placeholder="Enter Manufacturer Name" formControlName="manufacturer_name" [readonly]="product"
            [ngClass]="{'disble-box':product}">

          <span class="text-danger pt-1" *ngIf="
                            formControl('detailsForm').manufacturer_name.touched &&
                            formControl('detailsForm').manufacturer_name.errors?.required
                              ">
            <small>Manufacturer name is required.</small>
          </span>
        </div>
      </div>
    </div>
    <!--
            <div class="form-group w-100 float-left mb-0 mt-4">
                <div class="row pl-3">
                    <div class="col-lg-6 col-sm-12 d-flex pl-0  pl-md-0">
                        <label class="col-6 col-md-0 col-lg-3 mt-0 mt-md-3 text-secondary ellipses col-form-label">Manufacturer Name</label>

                        <select id="manufacturer_id" class="form-control border border-light" formControlName="manufacturer_id">

                            <option *ngFor="let manufacturer of manuList" [value]="manufacturer.id">{{manufacturer.first_name}}</option>

                        </select>
                        <span class="q-mark text-light border border-light d-inline-block m-3 rounded-circle text-center pr-2 pr-md-0 pl-2 pl-md-0 position-relative">?</span>

                    </div>

                </div>
            </div>
            -->

  </form>
</div>


<div class="driv-part mt-5">
  <h2 class="font-weight-bold mb-3 pl-4 font-size-18">Characteristics</h2>
  <div class="driv-tl p-4 rounded border border-light">
    <div class="responsive">
      <table class="table table-bordered mb-4 rounded">

        <thead>

          <tr>
            <th>Category</th>
            <th>Description</th>

            <th>Options</th>

          </tr>

        </thead>

        <tbody>

          <tr *ngFor="let char of caracteristics; let i = index">
            <td>{{char.caracteristique}}</td>
            <td>{{char.description}}</td>

            <td>

              <a class="edit-pen" (click)="editChar(i)"><i class="fas fa-pencil-alt"></i></a>

              <a (click)="removeChar(i)" class="cursor-pointer"><i class="fas fa-trash-alt"></i></a>

            </td>

          </tr>

          <tr *ngIf="caracteristics.length === 0">


            <td class="border-0"> No Characteristics </td>

          </tr>
        </tbody>

      </table>

    </div>

    <form class="driv-form">

      <div class="form-horizontal">

        <div class="form-group d-inline-block w-100">

          <label class="font-size-18  w-100 pl-1 pb-1">Category</label>

          <input type="text" class="form-control  border border-light wt-50" placeholder="Enter Category"
            [(ngModel)]="activeDocs.char.caracteristique" id="activeDocs.char.caracteristique"
            [ngModelOptions]="{standalone: true}">

        </div>

        <div class="form-group d-inline-block w-100">

          <span class="wt-50">
            <label class="font-size-18  w-100 pl-1 pb-1">Description</label>
            <input type="text" class="form-control  border border-light" placeholder="Enter Description"
              [(ngModel)]="activeDocs.char.description" id="activeDocs.char.description"
              [ngModelOptions]="{standalone: true}">

          </span>

          <span class="wt-50">

            <span class="file-tn d-inline-block position-relative">

              <button [disabled]="!activeDocs.char.caracteristique"
                class="btn btn-primary  rounded font-weight-bold font-size-18 pb-3 pt-3"
                (click)="addChars()">{{activeEdit >= 0 ? 'Save' : 'Add'}}</button>

            </span>

          </span>

        </div>
      </div>

    </form>

  </div>

</div>

<div class="film-part mt-4">

  <h2 class="font-weight-bold pl-3 mb-3 font-size-18">Images</h2>

  <div class="film-box rounded p-4 border border-light">

    <div class="img-pt rounded text-center d-inline-block"
      *ngFor="let file of files| filter: { 'type_id': FileType.image}; let i = index">

      <img [src]="file.buffer_url ? file.buffer_url : file.url" class="img-wrapper">

      <span><a (click)="removeFile(file)"><img src="../../../assets/images/close-icon.png"></a></span>

    </div>

    <div class="file-add-nt d-inline-block w-100 position-relative">

      <button value="file" class="rounded font-weight-bold mt-5 font-size-18 float-right position-relative"> Add
        Images</button>

      <input type="file" name="addImage" accept="image/*" id="file" value="file" (change)="selectFile($event, 'image')">

    </div>

  </div>

</div>

<div class="film-part mt-4">

  <h2 class="font-weight-bold pl-3 mb-3 font-size-18">Videos</h2>

  <div class="film-box rounded p-4  border border-light">

    <div class="img-pt rounded text-center d-inline-block"
      *ngFor="let file of files| filter: { 'type_id': FileType.video}; let i = index">

      <video *ngIf="!file.external_url" width="320" height="240" controls class="img-wrapper">
        <source [src]="file.url ? file.url : file.buffer_url">
      </video>
      <iframe *ngIf="file.external_url" [src]="file.external_url" width="120" height="120" frameborder="0"
        webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>

      <span><a (click)="removeFile(file)"><img src="../../../assets/images/close-icon.png"></a></span>

    </div>

    <form class="driv-form">
      <div class="form-group d-inline-block w-100">
        <div class="row">
          <span class="col-lg-8">
            <input type="text" [(ngModel)]="urlInput" id="urlInput" [ngModelOptions]="{standalone: true}"
              class="form-control mt-5 border border-light d-flex  rounded p-2 " placeholder="Add youtube/vimeo link">
          </span>
          <span class="col-lg-4 ">
            <span class="file-add-nt d-inline-block w-100 position-relative">
              <button [disabled]="!urlInput"
                class="rounded font-weight-bold mt-5 font-size-18 float-right position-relative"
                (click)="addExternalUrl('video')"> Submit</button>
            </span>
          </span>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="file-add-nt d-inline-block w-100 position-relative">
        <button class="rounded font-weight-bold mt-3 mr-2 font-size-18 float-right position-relative">Add
          Videos</button>
        <input type="file" name="addVideo" accept="video/*" (change)="selectFile($event, 'video')">

      </div>
    </div>

  </div>

</div>



<div class="driv-part mt-5">

  <h2 class="font-weight-bold mb-3 pl-4 font-size-18">Drivers</h2>

  <div class="driv-tl border border-light p-4 rounded">

    <div class="responsive">

      <table class="table table-bordered mb-4 rounded">

        <thead>

          <tr>

            <th>Description</th>

            <th>Options</th>

          </tr>

        </thead>

        <tbody>

          <tr *ngFor="let file of files| filter: { 'type_id': FileType.driver}; let i = index">

            <td>{{file.description}}</td>

            <td>

              <a class="edit-pen" [href]="file?.url? file?.url : file?.buffer_url" download="" target="blank"><img
                  src="../../../assets/images/download.png"></a>

              <a (click)=removeFile(file) class="cursor-pointer"><i class="fas fa-trash-alt"></i></a>

            </td>

          </tr>

          <tr *ngIf="(files | filter: { 'type_id': FileType.driver}).length === 0">


            <td class="border-0"> No Drivers </td>

          </tr>
        </tbody>

      </table>

    </div>

    <form class="driv-form">

      <div class="form-horizontal">

        <div class="form-group d-inline-block w-100">

          <label class="font-size-18  w-100 pl-1 pb-1">Description</label>

          <input type="text" class="form-control  border border-light d-flex  rounded p-2 wt-50"
            placeholder="The official driver for windows v2.54" [(ngModel)]="activeDocs.driver.description"
            id="activeDocsdriver.description" [ngModelOptions]="{standalone: true}">

        </div>
        <div class="form-group d-inline-block w-100">
          <div class="row">
            <div class="col-lg-6">

              <input type="text" class="form-control  border border-light d-flex  rounded p-2 "
                placeholder="C:/Drivers/driverV2.54.exe " [ngModelOptions]="{standalone: true}"
                [(ngModel)]="activeDocs.driver.name" [(ngModel)]="activeDocs.driver.name">

            </div>
            <div class="col-sm-12 col-lg-6 d-flex mt-0 mt-sm-2 justify-content-end mt-2 mt-lg-0">
              <input type="file" class="d-none" #driverinput (change)="selectFile($event, 'driver')">
              <button
                class="font-size-18 text-dark color-bg rounded border border-primary font-weight-bold mr-3 col-lg-4"
                (click)="driverinput.click()"> Choose File</button>

              <button (click)="uploadDoc('driver')"
                [disabled]="!activeDocs.driver.file || !activeDocs.driver.description"
                class="btn btn-primary rounded font-weight-bold font-size-18 pb-3 pt-3 col-lg-4">Add
                driver</button>

            </div>
          </div>

        </div>
      </div>

    </form>
  </div>

</div>



<div class="driv-part mt-5">

  <h2 class="font-weight-bold mb-3 pl-4 font-size-18">Document</h2>

  <div class="driv-tl p-4 rounded border border-light">

    <div class="responsive">

      <table class="table table-bordered mb-4 rounded">

        <thead>
          <tr>

            <th>Description</th>
            <th>Options</th>
          </tr>

        </thead>

        <tbody>

          <tr *ngFor="let file of files| filter: { 'type_id': FileType.document}; let i = index">

            <td>{{file.description}}</td>

            <td>

              <a class="edit-pen" [href]="file?.url? file?.url : file?.buffer_url" download="" target="blank"><img
                  src="../../../assets/images/download.png"></a>

              <a (click)="removeFile(file)" class="cursor-pointer"><i class="fas fa-trash-alt"></i></a>

            </td>

          </tr>

          <tr *ngIf="(files | filter: { 'type_id': FileType.document}).length === 0">
            <td class="border-0"> No Documents </td>

          </tr>

        </tbody>
      </table>
    </div>

    <form class="driv-form">
      <div class="form-horizontal">
        <div class="form-group d-inline-block w-100">

          <label class="font-size-18  w-100 pl-1 pb-1">Description</label>

          <input type="text" class="form-control  border border-light d-flex  rounded p-2  wt-50"
            placeholder="The official flyer 2016" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="activeDocs.document.description" [(ngModel)]="activeDocs.document.description">

        </div>
        <div class="form-group d-inline-block w-100">
          <div class="row">
            <div class="col-lg-6">

              <input type="text" class="form-control  border border-light d-flex  rounded p-2 "
                placeholder="C:/Drivers/flyer.pdf" [ngModelOptions]="{standalone: true}"
                [(ngModel)]="activeDocs.document.name" [(ngModel)]="activeDocs.document.name">

            </div>
            <div class="col-sm-12 col-lg-6 d-flex mt-0 mt-sm-2 justify-content-end mt-2 mt-lg-0">
              <input type="file" class="d-none" #documentinput (change)="selectFile($event, 'document')">
              <button
                class="font-size-18 text-dark rounded border border-primary color-bg font-weight-bold mr-3 col-lg-4"
                (click)="documentinput.click()"> Choose File</button>

              <button (click)="uploadDoc('document')"
                [disabled]="!activeDocs.document.file || !activeDocs.document.description"
                class="btn btn-primary rounded font-weight-bold font-size-18 pb-3 pt-3 col-lg-4">Add
                document</button>

            </div>
          </div>

        </div>

      </div>

    </form>
  </div>

</div>

<div class="driv-part mt-5">
  <h2 class="font-weight-bold mb-3 pl-4 font-size-18">Tags</h2>
  <div class="driv-tl p-4 rounded border border-light">
    <div class="responsive">
      <table class="table table-bordered mb-4 rounded">
        <thead>
          <tr>
            <th>Tags</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tag of tags; let i = index">
            <td>{{tag.tag_name}}</td>
            <td>
              <a class="edit-pen" (click)="editTag(i)"><i class="fas fa-pencil-alt"></i></a>
              <a (click)="removeTag(i)" class="cursor-pointer"><i class="fas fa-trash-alt"></i></a>
            </td>
          </tr>
          <tr *ngIf="tags.length === 0">
            <td class="border-0"> No Tags </td>
          </tr>
        </tbody>
      </table>
    </div>
    <form class="driv-form" [formGroup]="tagForm">
      <div class="form-horizontal">
        <div class="form-group d-inline-block w-100">

          <span class="wt-50">
            <label class="font-size-18  w-100 pl-1 pb-1">Tag</label>
            <input type="text" class="form-control  border border-light" placeholder="Enter Description"  formControlName="tagName"
           >
          </span>
          <span class="wt-50">
            <span class="file-tn d-inline-block position-relative">
              <button [disabled]="tagForm.invalid"
                class="btn btn-primary  rounded font-weight-bold font-size-18 pb-3 pt-3"
                (click)="addTag()">{{activeEdit >= 0 ? 'Save' : 'Add'}}</button>
            </span>
          </span>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="driv-part btn-div mt-5">
  <div class="driv-tl p-0 border-0">
    <form class="driv-form">
      <div class="form-horizontal">
        <div class="form-group">
          <div class="mt-3 d-flex justify-content-end">
            <button type="button"
              class="font-weight-bold rounded border border-primary btn text-dark pl-5 pb-3 pr-5 pt-3"
              (click)="doCancel()">Cancel</button>
            <button type="button" class="btn btn-primary rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
              [disabled]="!detailsForm.valid" (click)="addProduct()">Save</button>

          </div>
        </div>
      </div>

    </form>
  </div>

</div>
