import { Component, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { User } from 'src/app/models/user';
import { deleteUserById, getUsers } from 'src/app/services/manager.service';
import { ERROR_MESSAGES, PAGE_COUNT, SUCCESS_MESSAGES } from './../../common/constants';
import { Subject }  from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  public userList: User[];
  public page: number = 1;
  public pageCount: number = PAGE_COUNT;
  public totalPage: number = 0;
  searchText :string = "";
  searchUsersSubject : Subject<string> = new Subject();
  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getUsers(1);
    this.searchUsersSubject.pipe(debounceTime(500),distinctUntilChanged()).subscribe(()=>{
      this.getUsers(1);
    })
  }

  getUsers = async (page) => {
    this.page = page;
    this.spinner.show();
    try {
      let { results, totalCount } = await getUsers(null, this.searchText.trim() , null, this.page, this.pageCount, null);
      this.userList = results;
      this.totalPage = totalCount;
    } catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }

  }

  deleteUser = async (id) => {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.message = 'Are you sure you want to delete the User?';
    modalRef.componentInstance.showCancel = true;
    modalRef.result.then((result: any) => {
      console.log("comp closed", result);
      if (result) {
        this.handleDeleteUser(id);
      }
    });
  }

  handleDeleteUser = async (id) => {
    console.log(id);
    this.spinner.show();
    try {
      let { success } = await deleteUserById(id);
      if (success) {
        this.getUsers(1);
        this.toastr.success(SUCCESS_MESSAGES.USER_DELETED);
      }
    } catch (err) {
      this.toastr.error(err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR);
    } finally {
      this.spinner.hide();
    }
  }

  setPage = (page) => {
    this.getUsers(page);
  }

  searchUsers(event){
    this.searchUsersSubject.next(event)
  }
}
